import {dayOfYear, formatDate, formatTime, subtractDays, subtractHours, TODAY_DATE} from "./dateFunctions";
import {BBOX, URL_ROOT} from "../constants/constants";

export function getUrlGeoJSONs(datasetPPM, startDate, endDate, stepHours=3) {

    //TODO: split the dealing with

    // if endDate is today, get UTC hours, subtract 6, takes the max of 02 + 3 * i:59:59 that fits
    let validEndTime = '23'
    if (endDate === formatDate(TODAY_DATE, '-')) {
        validEndTime = String(Math.floor((new Date().getUTCHours() - 6) / stepHours) * stepHours - 1).padStart(2, '0')
    }

    const endTime = new Date(endDate + ` ${validEndTime}:59:59`)
    const startTime = new Date(startDate + ' 02:59:59')

    const urlGeoJSONs = []

    const index = Math.floor((endTime - startTime) / (stepHours * 60 * 60 * 1000))

    const dataset = datasetPPM.replace('precip', 'gpm')

    for (let step = 0; step <= index; step++) {
        let stepTime = subtractHours(stepHours * step, endTime)
        let urlGeoJSON = `https://pmmpublisher.pps.eosdis.nasa.gov/products/${dataset}/subset/Global/${stepTime.getFullYear()}/${dayOfYear(stepTime)}/${dataset}.${formatDate(stepTime)}.${formatTime(stepTime)}?bbox=${BBOX}`
        urlGeoJSONs.push(urlGeoJSON)
    }

    console.log(urlGeoJSONs)
    return urlGeoJSONs

}

export function getUrlGeoJSON(datasetPPM, delay=2) {
    const dataset = datasetPPM.replace('precip', 'gpm')
    let date = subtractHours(delay * 24, TODAY_DATE)
    let urlGeoJSON = `https://pmmpublisher.pps.eosdis.nasa.gov/products/${dataset}/subset/Global/${date.getFullYear()}/${dayOfYear(date)}/${dataset}.${formatDate(date)}?bbox=${BBOX}`
    return urlGeoJSON
}

export function getPathPPMs(days=15, todayDate = new Date()) {
    const pathPPMs = []
    for (let i=0; i<=days; i++) {
        for (let j=0; j<8; j++) {
            let todayDateFormat = `${formatDate(todayDate)}_${String(todayDate.getHours()).padStart(2, '0')}`
            let dateFormat = `${formatDate(subtractDays(days - i))}_${String(2+j*3).padStart(2, '0')}`
            if (dateFormat <= todayDateFormat) {
                pathPPMs.push(`${URL_ROOT}/rain/gpm_realtime/gpm_${formatDate(subtractDays(days - i))}_${String(2+j*3).padStart(2, '0')}.geojson`)
            }
        }
    }
    return pathPPMs
}

export function getPathGFSs(days=5) {
    const pathGFSs = []
    for (let d=0; d<days; d++) {
        for (let h=0; h<8; h++) {
            pathGFSs.push(`${URL_ROOT}/rain/gfs_realtime/gfs_${String(d * 24 + h * 3).padStart(3, '0')}h_${String((d + 1) * 24 + h * 3).padStart(3, '0')}h.geojson`)
        }
    }
    return pathGFSs
}


/*export function getUrlGeoJSON(datasetPPM, startDate, endDate) {
    const numberOfDays = (new Date(startDate) - new Date(endDate))/(24*60*60*1000) + 1
    const limit = datasetPPM.includes('3h') ? numberOfDays * 8 : datasetPPM.includes('30mn') ? numberOfDays * 24 : numberOfDays
    const dataset = datasetPPM.replace('precip', 'gpm')

    //`https://pmmpublisher.pps.eosdis.nasa.gov/products/gpm_3hr_1d/export/Global/2022/320/gpm_3hr_1d.20221116.085959.geojson`

    let urlGeoJSON = `https://pmmpublisher.pps.eosdis.nasa.gov/products/${dataset}/export/Global/2022/320/${dataset}.20221116.085959.geojson`

    let urlGeoJSON = `https://pmmpublisher.pps.eosdis.nasa.gov/opensearch?q=${dataset}&limit=${limit}&startTime=${startDate}&endTime=${endDate}`
    return fetch(urlGeoJSON)
        .then(response => response.json())
        .then(data => {
            const output = data.items.map(x => x.action[5].using[0].url)
            console.log('data', output)
            return output;
        })
}*/
