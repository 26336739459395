import {Box, Checkbox, Divider, FormControlLabel} from '@mui/material';
import {DataContext} from "../contexts/DataContext";
import React, {useState, useContext, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import {Tooltip} from "@mui/material";

export default function SelectedExplorationLayers() {

    const { t } = useTranslation();

    const {
        pastPeakWind,
        pastPeakWater,
        pastRainTotal,
        accRain3h,
        accRain1d,
        fcstRain1d,
        fcstRain5d,
        accRain1dVideo,
        fcstRain1dVideo,
        updateAccRain3h,
        updateAccRain1d,
        updateFcstRain1d,
        updateFcstRain5d,
        updateAccRain1dVideo,
        updateFcstRain1dVideo,
        updatePastPeakWind,
        updatePastPeakWater,
        updatePastRainTotal,
        updateExcessRain,
        population,
        value,
        updatePopulation,
        updateValue,
        adm0,
        adm1,
        adm2,
        stochastic_heatmap,
        stochastic_trajectories,
        updateAdm0,
        updateAdm1,
        updateAdm2,
        updateStochasticHeatmap,
        updateStochasticTrajectories,
    } = useContext(DataContext);


    //const [checked, setChecked] = React.useState([past_peak_wind, past_peak_water]);

    const [checked, setChecked] = useState({
        pastPeakWind: pastPeakWind,
        pastPeakWater: pastPeakWater,
        pastRainTotal: pastRainTotal,
        accRain3h: accRain3h,
        accRain1d: accRain1d,
        accRain1dVideo: accRain1dVideo,
        fcstRain1d: fcstRain1d,
        fcstRain5d: fcstRain5d,
        fcstRain1dVideo: fcstRain1dVideo,
        population: population,
        value: value,
        adm0: adm0,
        adm1: adm1,
        adm2: adm2,
        stochastic_heatmap: stochastic_heatmap,
        stochastic_trajectories: stochastic_trajectories
    })

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked, event.target.checked]);
    };

    const handleChangePastPeakWind = (event) => {
        updatePastPeakWind(event.target.checked);
        setChecked({...checked, pastPeakWind: event.target.checked});
    };

    const handleChangePastPeakWater = (event) => {
        updatePastPeakWater(event.target.checked);
        setChecked({...checked, pastPeakWater: event.target.checked});
    };

    const handleChangePastRainTotal = (event) => {
        updatePastRainTotal(event.target.checked);
        setChecked({...checked, pastRainTotal: event.target.checked});
    };

    const handleChangeAccRain3h = (event) => {
        updateAccRain3h(event.target.checked);
        setChecked({...checked, accRain3h: event.target.checked});
        if (accRain1d) {
            updateAccRain1d(!accRain1d)
            setChecked({...checked, accRain1d: false});
        }
        if (accRain1dVideo) {
            updateAccRain1dVideo(!accRain1dVideo)
            setChecked({...checked, accRain1dVideo: false});
        }
        if (fcstRain1d) {
            updateFcstRain1d(!fcstRain1d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain5d) {
            updateFcstRain5d(!fcstRain5d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain1dVideo) {
            updateFcstRain1dVideo(!fcstRain1dVideo)
            setChecked({...checked, fcstRain1dVideo: false});
        }
    };

    const handleChangeAccRain1d = (event) => {
        updateAccRain1d(event.target.checked);
        setChecked({...checked, accRain1d: event.target.checked});
        if (accRain3h) {
            updateAccRain3h(!accRain3h)
            setChecked({...checked, accRain3h: false});
        }
        if (accRain1dVideo) {
            updateAccRain1dVideo(!accRain1dVideo)
            setChecked({...checked, accRain1dVideo: false});
        }
        if (fcstRain1d) {
            updateFcstRain1d(!fcstRain1d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain5d) {
            updateFcstRain5d(!fcstRain5d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain1dVideo) {
            updateFcstRain1dVideo(!fcstRain1dVideo)
            setChecked({...checked, fcstRain1dVideo: false});
        }
    };

    const handleChangeAccRain1dVideo = (event) => {
        updateAccRain1dVideo(event.target.checked);
        setChecked({...checked, accRain1dVideo: event.target.checked});
        if (accRain3h) {
            updateAccRain3h(!accRain3h)
            setChecked({...checked, accRain3h: false});
        }
        if (accRain1d) {
            updateAccRain1d(!accRain1d)
            setChecked({...checked, accRain1d: false});
        }
        if (fcstRain1d) {
            updateFcstRain1d(!fcstRain1d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain5d) {
            updateFcstRain5d(!fcstRain5d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain1dVideo) {
            updateFcstRain1dVideo(!fcstRain1dVideo)
            setChecked({...checked, fcstRain1dVideo: false});
        }
    };

    const handleChangeFcstRain1d = (event) => {
        updateFcstRain1d(event.target.checked);
        setChecked({...checked, fcstRain1d: event.target.checked});
        if (accRain3h) {
            updateAccRain3h(!accRain3h)
            setChecked({...checked, accRain3h: false});
        }
        if (accRain1d) {
            updateAccRain1d(!accRain1d)
            setChecked({...checked, accRain1d: false});
        }
        if (accRain1dVideo) {
            updateAccRain1dVideo(!accRain1dVideo)
            setChecked({...checked, accRain1dVideo: false});
        }
        if (fcstRain5d) {
            updateFcstRain5d(!fcstRain5d)
            setChecked({...checked, fcstRain5d: false});
        }
        if (fcstRain1dVideo) {
            updateFcstRain1dVideo(!fcstRain1dVideo)
            setChecked({...checked, fcstRain1dVideo: false});
        }
    };

    const handleChangeFcstRain5d = (event) => {
        updateFcstRain5d(event.target.checked);
        setChecked({...checked, fcstRain5d: event.target.checked});
        if (accRain3h) {
            updateAccRain3h(!accRain3h)
            setChecked({...checked, accRain3h: false});
        }
        if (accRain1d) {
            updateAccRain1d(!accRain1d)
            setChecked({...checked, accRain1d: false});
        }
        if (accRain1dVideo) {
            updateAccRain1dVideo(!accRain1dVideo)
            setChecked({...checked, accRain1dVideo: false});
        }
        if (fcstRain1d) {
            updateFcstRain1d(!fcstRain1d)
            setChecked({...checked, fcstRain1d: false});
        }
        if (fcstRain1dVideo) {
            updateFcstRain1dVideo(!fcstRain1dVideo)
            setChecked({...checked, fcstRain1dVideo: false});
        }
    };

    const handleChangeFcstRain1dVideo = (event) => {
        updateFcstRain1dVideo(event.target.checked);
        setChecked({...checked, fcstRain1dVideo: event.target.checked});
        if (accRain3h) {
            updateAccRain3h(!accRain3h)
            setChecked({...checked, accRain3h: false});
        }
        if (accRain1d) {
            updateAccRain1d(!accRain1d)
            setChecked({...checked, accRain1d: false});
        }
        if (accRain1dVideo) {
            updateAccRain1dVideo(!accRain1dVideo)
            setChecked({...checked, accRain1dVideo: false});
        }
        if (accRain1dVideo) {
            updateAccRain1dVideo(!accRain1dVideo)
            setChecked({...checked, accRain1dVideo: false});
        }
        if (fcstRain1d) {
            updateFcstRain1d(!fcstRain1d)
            setChecked({...checked, fcstRain1d: false});
        }
    };

    const handleChangePopulation = (event) => {
        updatePopulation(event.target.checked);
        setChecked({...checked, population: event.target.checked});
    };

    const handleChangeValue = (event) => {
        updateValue(event.target.checked);
        setChecked({...checked, value: event.target.checked});
    };

    const handleChangeAdm0 = (event) => {
        updateAdm0(event.target.checked);
        setChecked({...checked, adm0: event.target.checked});
        if (adm1) {
            updateAdm1(!adm1)
            setChecked({...checked, adm1: false});
        }
        if (adm2) {
            updateAdm2(!adm2)
            setChecked({...checked, adm2: false});
        }
    };

    const handleChangeAdm1 = (event) => {
        updateAdm1(event.target.checked);
        setChecked({...checked, adm1: event.target.checked});
        if (adm0) {
            updateAdm0(!adm0)
            setChecked({...checked, adm0: false});
        }
        if (adm2) {
            updateAdm2(!adm2)
            setChecked({...checked, adm2: false});
        }
    };

    const handleChangeAdm2 = (event) => {
        updateAdm2(event.target.checked);
        setChecked({...checked, adm2: event.target.checked});
        if (adm1) {
            updateAdm1(!adm1)
            setChecked({...checked, adm1: false});
        }
        if (adm0) {
            updateAdm0(!adm0)
            setChecked({...checked, adm0: false});
        }
    };

    const handleChangeStochasticHeatmap = (event) => {
        updateStochasticHeatmap(event.target.checked);
        setChecked({...checked, stochastic_heatmap: event.target.checked});
    };

    const handleChangeStochasticTrajectories = (event) => {
        updateStochasticTrajectories(event.target.checked);
        setChecked({...checked, stochastic_trajectories: event.target.checked});
    };


    const children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.wind.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.wind.title')}
                    control={<Checkbox checked={checked.pastPeakWind} onChange={handleChangePastPeakWind} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.surge.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.surge.title')}
                    control={<Checkbox checked={checked.pastPeakWater} onChange={handleChangePastPeakWater} />}
                />
            </Tooltip>
            <Divider />
{/*            <Tooltip placement={'top'} title={t('buttons.exploration.menu.accRain3h.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.accRain3h.title')}
                    control={<Checkbox checked={checked.accRain3h} onChange={handleChangeAccRain3h} />}
                />
            </Tooltip>*/}
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.accRain1d.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.accRain1d.title')}
                    control={<Checkbox checked={checked.accRain1d} onChange={handleChangeAccRain1d} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.accRain1dVideo.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.accRain1dVideo.title')}
                    control={<Checkbox checked={checked.accRain1dVideo} onChange={handleChangeAccRain1dVideo} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.fcstRain1d.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.fcstRain1d.title')}
                    control={<Checkbox checked={checked.fcstRain1d} onChange={handleChangeFcstRain1d} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.fcstRain5d.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.fcstRain5d.title')}
                    control={<Checkbox checked={checked.fcstRain5d} onChange={handleChangeFcstRain5d} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.fcstRain1dVideo.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.fcstRain1dVideo.title')}
                    control={<Checkbox checked={checked.fcstRain1dVideo} onChange={handleChangeFcstRain1dVideo} />}
                />
            </Tooltip>
            <Divider />
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.population.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.population.title')}
                    control={<Checkbox checked={checked.population} onChange={handleChangePopulation} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.economic.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.economic.title')}
                    control={<Checkbox checked={checked.value} onChange={handleChangeValue} />}
                />
            </Tooltip>
            <Divider />
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.adm0.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.adm0.title')}
                    control={<Checkbox checked={checked.adm0} onChange={handleChangeAdm0} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.adm1.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.adm1.title')}
                    control={<Checkbox checked={checked.adm1} onChange={handleChangeAdm1} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.adm2.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.adm2.title')}
                    control={<Checkbox checked={checked.adm2} onChange={handleChangeAdm2} />}
                />
            </Tooltip>
            <Divider />
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.heatmap.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.heatmap.title')}
                    control={<Checkbox checked={checked.stochastic_heatmap} onChange={handleChangeStochasticHeatmap} />}
                />
            </Tooltip>
            <Tooltip placement={'top'} title={t('buttons.exploration.menu.trajectories.helper')}>
                <FormControlLabel
                    label={t('buttons.exploration.menu.trajectories.title')}
                    control={<Checkbox checked={checked.stochastic_trajectories} onChange={handleChangeStochasticTrajectories} />}
                />
            </Tooltip>
        </Box>
    );

    return (
        <div>
            {children}
        </div>
    );
}
