import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import SelectedPopulationValue from "./selectedPopulationValue";
import SelectedAdm from "./selectedAdm";
import SelectedExplorationLayers from "./selectedExplorationLayers";
import {useContext} from "react";
import {DataContext} from "../contexts/DataContext";
import {Tooltip} from "@mui/material";
import CloseButton from "./closeButton";

export default function SelectedExploration() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const { t } = useTranslation();

    const {
        updateStormsToShow
    } = useContext(DataContext);

    const handleClick = (event) => {
        updateStormsToShow([])
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ minWidth: 120, boxShadow: 20, marginLeft:2}}>
            <Tooltip placement={'top'} title={t('buttons.exploration.helper')}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {t('buttons.exploration.title')}
                </Button>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <CloseButton handleClose={handleClose} />
                <SelectedExplorationLayers />
                {/*<SelectedPopulationValue />*/}
                {/*<SelectedAdm />*/}
            </Menu>
        </Box>
    );
}
