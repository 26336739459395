import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Tooltip} from "@mui/material";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PieChartCountry from "./pieChartCountry";
import { ReactComponent as Logo } from './img/combined.svg';
import { ReactComponent as TCP } from './img/tcp.svg';
import ReportTable from "./reportTable";
import { ReactComponent as ArcLogo } from './img/arc-logo.svg';
import {payout} from "../utils/payoutFunctions";
import {numberWithCommas} from "../utils/formatFunctions";
import ReportTableExport from "./reportTableExport";
import CloseButton from "./closeButton";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.9
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '90vw',
        height: '80vh',
        overflow:'scroll'
    },
}));
export default function ReportPopUp(props) {

    const { t } = useTranslation();

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [mapImage, setMapImage] = useState(null);

    const {storm_name, id, map, currentLosses} = props;

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function mapToURL(map) {
        map.once('render', () => {
            setMapImage(map.getCanvas().toDataURL('image/png'))
        })
    }

    mapToURL(map)

    return (
        <div className={'button-report'}>
            <Tooltip placement={'top'} title={t('buttons.report.helper')}>
                <Button variant="contained" color="secondary" onClick={handleOpen}>
                    {t('buttons.report.title')}
                </Button>
            </Tooltip>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 0,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <CloseButton handleClose={handleClose} />
                        <ArcLogo />
                        <h2>{storm_name}</h2>
                        <h3>id: {id}</h3>
                        {mapImage &&
                            <div className={'map-report'}>
                                <img src={mapImage} style={{ width: "80%", margin: "30px 0" }}/>
                            </div>
                        }
                        {/*<EnhancedTable />*/}
                        <Tooltip placement={'top'} title={t('report.pop_losses.helper')}>
                            <div className="long-title"><h3>{t('report.pop_losses.title')}</h3></div>
                        </Tooltip>
                        <PieChartCountry currentLosses={currentLosses}/>
{/*                        Payout:
                        {currentLosses.map(x => (
                            <div>{x.adm0_name} : ${numberWithCommas(payout(9890026,0.0037,2704142526,17562830,x.loss))}</div>
                            ))}*/}
                        {/*<ReportTable currentLosses={currentLosses} />*/}
                        <ReportTableExport currentLosses={currentLosses} storm_name={storm_name} storm_id={id}/>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
