import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useContext, useState} from "react";
import {WIND_TEXT} from "../constants/constants";
import {DataContext} from "../contexts/DataContext";

function numberWithCommas(x, prefix='') {
    return prefix + Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function createData(adm0_name, loss, population, wind_cat, adm1) {
    return {
        adm0_name,
        loss,
        population,
        wind_cat,
        adm1,
    };
}

const tableRowStyle = { fontSize: '8pt'};

function Row(props) {
    const { row, readPayout, L, AP, y, EP} = props;
    const [openAdm0, setOpenAdm0] = React.useState(false);
    const [openAdm1, setOpenAdm1] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', fontSize: '8pt' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenAdm0(!openAdm0)}
                    >
                        {openAdm0 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.adm0_name}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">{numberWithCommas(row.loss, '$')}</TableCell>
                <TableCell align="right">{numberWithCommas(row.population)}</TableCell>
                <TableCell align="right">{row.wind_cat[0] ? numberWithCommas(row.wind_cat[0]) : ''}</TableCell>
                <TableCell align="right">{row.wind_cat[1] ? numberWithCommas(row.wind_cat[1]) : ''}</TableCell>
                <TableCell align="right">{row.wind_cat[2] ? numberWithCommas(row.wind_cat[2]) : ''}</TableCell>
                <TableCell align="right">{row.wind_cat[3] ? numberWithCommas(row.wind_cat[3]) : ''}</TableCell>
                <TableCell align="right">{row.wind_cat[4] ? numberWithCommas(row.wind_cat[4]) : ''}</TableCell>
                <TableCell align="right">{row.wind_cat[5] ? numberWithCommas(row.wind_cat[5]) : ''}</TableCell>
                {/*{readPayout && <TableCell align="right">{numberWithCommas(payout(9890026,0.0037,2704142526,17562830, row.loss), '$')}</TableCell>}*/}
            </TableRow>
            {openAdm0 && row.adm1.map((adm1Row, i) => ([
                <TableRow key={i} sx={{ '& > *': { borderBottom: 'unset', fontSize: '8pt' } }}>
                    <TableCell></TableCell>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenAdm1(!openAdm1)}
                        >
                            {openAdm1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{adm1Row.adm1_name}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">{numberWithCommas(adm1Row.loss, '$')}</TableCell>
                    <TableCell align="right">{numberWithCommas(adm1Row.population)}</TableCell>
                    <TableCell align="right">{adm1Row.wind_cat[0] ? numberWithCommas(adm1Row.wind_cat[0]) : ''}</TableCell>
                    <TableCell align="right">{adm1Row.wind_cat[1] ? numberWithCommas(adm1Row.wind_cat[1]) : ''}</TableCell>
                    <TableCell align="right">{adm1Row.wind_cat[2] ? numberWithCommas(adm1Row.wind_cat[2]) : ''}</TableCell>
                    <TableCell align="right">{adm1Row.wind_cat[3] ? numberWithCommas(adm1Row.wind_cat[3]) : ''}</TableCell>
                    <TableCell align="right">{adm1Row.wind_cat[4] ? numberWithCommas(adm1Row.wind_cat[4]) : ''}</TableCell>
                    <TableCell align="right">{adm1Row.wind_cat[5] ? numberWithCommas(adm1Row.wind_cat[5]) : ''}</TableCell>
                </TableRow>,
                openAdm1 && adm1Row.adm2.map((adm2Row, j) => (
                    <TableRow key={j} sx={{ '& > *': { borderBottom: 'unset', fontSize: '8pt' } }}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row">{adm2Row.adm2_name}</TableCell>
                        <TableCell align="right">{numberWithCommas(adm2Row.loss, '$')}</TableCell>
                        <TableCell align="right">{numberWithCommas(adm2Row.population)}</TableCell>
                        <TableCell align="right">{adm2Row.wind_cat[0] ? numberWithCommas(adm2Row.wind_cat[0]) : ''}</TableCell>
                        <TableCell align="right">{adm2Row.wind_cat[1] ? numberWithCommas(adm2Row.wind_cat[1]) : ''}</TableCell>
                        <TableCell align="right">{adm2Row.wind_cat[2] ? numberWithCommas(adm2Row.wind_cat[2]) : ''}</TableCell>
                        <TableCell align="right">{adm2Row.wind_cat[3] ? numberWithCommas(adm2Row.wind_cat[3]) : ''}</TableCell>
                        <TableCell align="right">{adm2Row.wind_cat[4] ? numberWithCommas(adm2Row.wind_cat[4]) : ''}</TableCell>
                        <TableCell align="right">{adm2Row.wind_cat[5] ? numberWithCommas(adm2Row.wind_cat[5]) : ''}</TableCell>
                    </TableRow>
                ))
            ]))}



{/*            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>*/}
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        adm0_name: PropTypes.string.isRequired,
        loss: PropTypes.number.isRequired,
        population: PropTypes.number.isRequired,
        wind_cat: PropTypes.objectOf(
            PropTypes.shape({
                0: PropTypes.number,
                1: PropTypes.number,
                2: PropTypes.number,
                3: PropTypes.number,
                4: PropTypes.number,
                5: PropTypes.number,
            }),
        ).isRequired,
/*        adm1: PropTypes.arrayOf(
            PropTypes.shapes({
                adm1_name: PropTypes.string.isRequired,
                loss: PropTypes.number.isRequired,
                population: PropTypes.number.isRequired,
                wind_cat: PropTypes.objectOf(
                    PropTypes.shape({
                        0: PropTypes.number,
                        1: PropTypes.number,
                        2: PropTypes.number,
                        3: PropTypes.number,
                        4: PropTypes.number,
                        5: PropTypes.number,
                    }),
                ).isRequired,
                adm2: PropTypes.arrayOf(
                    PropTypes.shapes({
                        adm2_name: PropTypes.string.isRequired,
                        loss: PropTypes.number.isRequired,
                        population: PropTypes.number.isRequired,
                        wind_cat: PropTypes.objectOf(
                            PropTypes.shape({
                                0: PropTypes.number,
                                1: PropTypes.number,
                                2: PropTypes.number,
                                3: PropTypes.number,
                                4: PropTypes.number,
                                5: PropTypes.number,
                            }),
                        ).isRequired,
                    }),
                ).isRequired,
            }),
        ).isRequired,*/
    }).isRequired,
};

export default function ReportTable(props) {
    const {currentLosses} = props;

    const {
        attachmentPoint,
        aggregateLimit,
        cedingPercentage,
        exhaustionPoint
    } = useContext(DataContext);

    const [rows, setRows] = useState([])

    for (const country of currentLosses) {
        rows.push(createData(country.adm0_name, country.loss, country.population, country.wind_cat, country.adm1))
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset', fontSize: '8pt' } }}>
                        <TableCell />
                        <TableCell>Adm 0</TableCell>
                        <TableCell align="right">Adm 1</TableCell>
                        <TableCell align="right">Adm 2</TableCell>
                        <TableCell align="right">Loss</TableCell>
                        <TableCell align="right">Population</TableCell>
                        <TableCell align="right">{WIND_TEXT[0]}</TableCell>
                        <TableCell align="right">{WIND_TEXT[1]}</TableCell>
                        <TableCell align="right">{WIND_TEXT[2]}</TableCell>
                        <TableCell align="right">{WIND_TEXT[3]}</TableCell>
                        <TableCell align="right">{WIND_TEXT[4]}</TableCell>
                        <TableCell align="right">{WIND_TEXT[5]}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row
                            key={row.adm0_name}
                            row={row}
                            AP={attachmentPoint}
                            L={aggregateLimit}
                            y={cedingPercentage}
                            EP={exhaustionPoint}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
