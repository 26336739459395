import {Divider, Paper} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import {Tooltip} from "@mui/material";
import HistoricalChart from "./historicalChart";
import {getFlagCountry} from "../utils/miscFunctions";
import Draggable from "react-draggable";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloseButton from "./closeButton";

export default function AdmOverlay(props) {

    const {admName, admPopulation, admValue, admAvgStorm, admBiggestLoss, data, clearMap} = props;

    const { t } = useTranslation();

    const [open, setOpen] = useState(props.open)

    const handleClose = () => {
        clearMap();
        setOpen(false);
    };

    useEffect(() => {
        setOpen(true)
    }, [admName])

    return (
        <div>
            {admName &&
            <Draggable>
                <div className='overlay' style={{width: '45vw', bottom: '2vh', left: '10vw'}}>
                    {
                        open &&
                        <Paper
                            elevation={15}
                            sx={{backgroundColor: 'rgba(234,234,234,0.72)'}}
                        >
                            {/*                        <IconButton className='close-button'>
                            <CloseIcon />
                        </IconButton>*/}
                            {/*{props.adm_name}*/}
                            <CloseButton handleClose={handleClose} />
                            <div className="exposure-info">
                                {/*<img src={getFlagCountry(admName.adm0_name)} width="30" height="20"/>*/}
                                <Tooltip placement={'top'} title={t('adm_overlay.information.location.helper')}>
                                    <div><strong>{t('adm_overlay.information.location.title')}</strong>&nbsp;<span id="location" className={'location'}>{admName.adm0_name}{admName.adm1_name && ', ' + admName.adm1_name}{admName.adm2_name && ', ' + admName.adm2_name}</span></div>
                                </Tooltip>
                                <Tooltip placement={'top'} title={t('adm_overlay.information.population.helper')}>
                                    <div><strong>{t('adm_overlay.information.population.title')}</strong>&nbsp;<span id="population" className={'population'}>{admPopulation}</span></div>
                                </Tooltip>
                                <Tooltip placement={'top'} title={t('adm_overlay.information.value.helper')}>
                                    <div><strong>{t('adm_overlay.information.value.title')}</strong>&nbsp;<span id="value" className={'value'}>${admValue}</span></div>
                                </Tooltip>
                                <Tooltip placement={'top'} title={t('adm_overlay.information.average.helper')}>
                                    <div><strong>{t('adm_overlay.information.average.title')}</strong>&nbsp;<span>{admAvgStorm} storm(s) / year</span></div>
                                </Tooltip>
                                <Tooltip placement={'top'} title={t('adm_overlay.information.largest_loss.helper')}>
                                    <div><strong>{t('adm_overlay.information.largest_loss.title')}</strong>&nbsp;<span id="loss" className={'loss'}>${admBiggestLoss}</span></div>
                                </Tooltip>
                                {/*                        {storm && <div><strong>Total loss:</strong>&nbsp;<span id="tloss" className={'tloss'}></span></div>}
                    {storm && <Divider />}*/}
                                {/**** ADDING STATS*/}

                            </div>
                            {/*                    <IconButton aria-label="delete" className={'close-button'} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>*/}
                            <HistoricalChart data={data}/>
                        </Paper>
                    }
                </div>
            </Draggable>
            }
        </div>
    )
}

