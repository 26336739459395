import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import MuiInput from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import NumberFormat from "react-number-format";
import {TextField} from "@mui/material";

const Input = styled(MuiInput)`
  width: 100px;
`;

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            // isNumericString
        />
    );
}

export default function RiskParametersInput(props) {

    const {name, percent, initialValue, updateValue} = props;

    const handleInputChange = (event) => {
        updateValue(event.target.value > 0 ? percent ? parseFloat(event.target.value).toFixed(2) * 100 : parseInt(event.target.value) : 0);
    };

    return (
        <div>
            <TextField
                id="outlined-basic"
                //label={name}
                helperText={name}
                variant="outlined"
                value={percent ? (initialValue / 100).toFixed(2) : initialValue.toFixed(2)}
                onChange={handleInputChange}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
            />
        </div>
    );
}



