import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import MuiInput from '@mui/material/Input';
import Grid from '@mui/material/Grid';

const Input = styled(MuiInput)`
  width: 42px;
`;

function valuetext(value) {
    return `${value}%`;
}

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 20,
        label: '20%',
    },
    {
        value: 40,
        label: '40%',
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 80,
        label: '80%',
    },
    {
        value: 100,
        label: '100%',
    },
];

export default function RiskParametersSlider(props) {

    const {name, initialValue, updateValue} = props;

    //const [value, setValue] = useState(initialValue);

    const handleSliderChange = (event, newValue) => {
        updateValue(newValue);
    };

    const handleInputChange = (event) => {
        //updateValue(event.target.value === '' ? 0 : Number(event.target.value));
        updateValue(Number(event.target.value));
    };

    return (
        <div>
            <Typography id="input-slider" gutterBottom>
                {name}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        defaultValue={initialValue}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        onChange={handleSliderChange}
                        getAriaValueText={valuetext}
                        marks={marks}
                        min={0}
                        max={100}
                        valueLabelDisplay="on"
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={initialValue}
                        size="small"
                        onChange={handleInputChange}
                        inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}



