import * as React from 'react';
import { useTranslation } from 'react-i18next';
//import {Tooltip} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useContext, useEffect, useState} from "react";
import {DataContext} from "../contexts/DataContext";
import {CAT_KNOTS, WIND_COLORS, KNTS2KMH, wind_categories} from "../constants/constants";
import {windCategory} from "../utils/miscFunctions";

/*const wind_categories = [
    {name: 'Disturbance', short: 'B'},
    {name: 'Tropical depression', short: 'D'},
    {name: 'Moderate tropical storm', short: 'S'},
    {name: 'Severe tropical storm', short: 'SS'},
    {name: 'Tropical cyclone', short: 'C'},
    {name: 'Intense tropical cyclone', short: 'IC'},
    {name: 'Very intense tropical cyclone', short: 'VC'},
];*/

const windSpeedUnitsConversion = [
    {unit: 'km/h', conversion: 1},
    {unit: 'm/s', conversion: 0.277778},
    {unit: 'mph', conversion: 0.621371},
    {unit: 'knots', conversion: 0.539957},
]

function formatDateMMDD(d)
{
    let date = new Date(d);

    if ( isNaN( date .getTime() ) )
    {
        return d;
    }
    else
    {

        let month = [];
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sept";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        let day = date.getDate();

        if(day < 10)
        {
            day = "0"+day;
        }

        return    month[date.getMonth()] + " " + day;
    }

}

function createData(date, time, type, wind, DTG) {
    return {
        date,
        time,
        type,
        wind,
        DTG
    };
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip placement={'top'} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffffff',
        color: 'rgba(0,0,0,0.66)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function StormTable() {

    const { t } = useTranslation();

    const [rows, setRows] = useState([])
    const [units, setUnits] = useState(0)
    const [selectedColor, setSelectedColor] = useState('rgba(255,255,255,0)')
    const [hoveredColor, setHoveredColor] = useState('rgba(255,255,255,0)')

    const {
        selectedStorm,
        selectedDTG,
        updateSelectedDTG
    } = useContext(DataContext);

    useEffect(() => {
        if (selectedStorm) {
            setRows([])
            fetch(selectedStorm.shp)
                .then(resp => resp.json())
                .then(resp => resp.features.filter(x => x.geometry.type !== 'LineString' && (x.properties.BASIN ? x.properties.BASIN === 'SH' : true) && (x.properties.NAME === selectedStorm.storm_name)))
                .then(result => result.map(x => ({date: formatDateMMDD(x.properties.DTG.split(/(\s+)/)[0]), time: x.properties.DTG.split(/(\s+)/)[2].slice(0,5), type: x.properties.VMAX, wind: x.properties.VMAX, DTG: x.properties.DTG})))
                .then(result => result.sort((a,b) => new Date(a.DTG) - new Date(b.DTG)))
                .then(result => result)
                .then(result => {
                    let array = []
                    let uniqueDTGs = []
                    const result_filtered = result.filter(element => {   // only keep unique DTGs
                        const isDuplicate = uniqueDTGs.includes(element.DTG);

                        if (!isDuplicate) {
                            uniqueDTGs.push(element.DTG);
                            return true;
                        }
                    })
                    result_filtered.reverse().forEach((item) => {
                        array.push(createData(item.date, item.time, item.type, item.wind, item.DTG))
                    })
                    setRows(array)
                })
        }
    }, [selectedStorm])

    const handleWindClick = () => {
        setUnits((units+1) % windSpeedUnitsConversion.length);
    };

    const handleSelectedRow = (name, speed) => {
        updateSelectedDTG(name)
        setSelectedColor(WIND_COLORS[windCategory(speed)])
    }

    const handleMouseEnterRow = (speed) => {
        setHoveredColor(WIND_COLORS[windCategory(speed)])
    }

    function scrollTo(row) {
        if (row) {
            const selected = document.getElementById(row);
            selected.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }

    useEffect(() => {
        scrollTo(selectedDTG)
    }, [selectedDTG])

    const columns = [
        {
            id: 'date',
            label: t('storm_overlay.table.date'),
            minWidth: 35,
            //format: (value) => formatDate(value),
        },
        {
            id: 'time',
            label: t('storm_overlay.table.time'),
            minWidth: 15
        },
        {
            id: 'type',
            label: t('storm_overlay.table.type'),
            align: 'right',
            //format: (value) => <span className={'cyclone-type'}>{wind_categories[windCategory(value)].short}</span>,
        },
        {
            id: 'wind',
            label: t('storm_overlay.table.wind'),
            minWidth: 20,
            align: 'right',
            format: (value) => (value * KNTS2KMH).toFixed(0),
            subtitle: 'km/h',
        },
    ];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: 'rgba(255,255,255,0)'}}>
            <TableContainer sx={{ maxHeight: 330 }}>
                <Table aria-label="sticky table" size={'small'} >
                    <TableHead sx={{backgroundColor: 'rgba(255,255,255,0)'}}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontSize: '12px', verticalAlign: 'top', lineHeight: '10px', textAlign: 'right'}}
                                >
                                    <div>
                                        <strong>{column.label}</strong><br/>
                                        <span className={'subtitle'} onClick={handleWindClick}>
                                            {column.subtitle ? windSpeedUnitsConversion[units].unit : ''}
                                        </span>
                                    </div>
                                    {/*{column.subtitle && <div style={{ fontSize: 'small', fontWeight: 300 }}>{column.subtitle}</div>}*/}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            '& tr:hover:hover': {
                                backgroundColor: hoveredColor
                            },
                            '& tr:focus': {
                                backgroundColor: selectedColor,
                            },
                        }}
                    >
                        {rows
                            .map((row, i) => {
                                return (
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <span
                                                    className={'cyclone-type'}
                                                    style={{
                                                        backgroundColor: WIND_COLORS[windCategory(row.wind)],
                                                        color: ['B','D','IC','VC'].includes(wind_categories[windCategory(row.wind)].short) ? 'white' : '',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {wind_categories[windCategory(row.wind)].short}
                                                </span>
                                                {t(`constants.wind_cats.${windCategory(row.wind)}.title`)}
                                                <div>
                                                    {t(`constants.wind_cats.${windCategory(row.wind)}.helper`)}
                                                </div>
                                            </React.Fragment>
                                        // `${t(`constants.wind_cats.${windCategory(row.wind)}.title`)} -> ${t(`constants.wind_cats.${windCategory(row.wind)}.helper`)}`
                                    }
                                    >
                                        <TableRow
                                            id={row.DTG}
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.DTG}
                                            onClick={(event) => handleSelectedRow(row.DTG, row.wind)}
                                            onMouseEnter={(event) => handleMouseEnterRow(row.wind)}
                                            style={{
                                                backgroundColor: selectedDTG &&  row.DTG === selectedDTG ? WIND_COLORS[windCategory(row.wind)] : ''
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            fontSize: column.id !== 'type' && '11px',
                                                            paddingTop: 0, // column.id === 'type' && 0,
                                                            paddingBottom: 0, // column.id === 'type' && 0,
                                                            border: 0, // column.id === 'type' && 0,
                                                        }}>
                                                        {
                                                            column.id !== 'type' ?
                                                                column.format && typeof value === 'number' ?
                                                                    column.format(value * windSpeedUnitsConversion[units].conversion) :
                                                                    value
                                                                :
                                                                <span
                                                                    className={'cyclone-type'}
                                                                    style={{
                                                                        backgroundColor: WIND_COLORS[windCategory(value)],
                                                                        color: ['B','D','IC','VC'].includes(wind_categories[windCategory(value)].short) ? 'white' : '',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                            {wind_categories[windCategory(value)].short}
                                                        </span>
                                                        }
                                                        {/*                                                    {column.format && typeof value === 'number' && column.id !== 'type'
                                                        ? column.format(value)
                                                        : value}
                                                    {column.id === 'type' &&
                                                        <span
                                                            className={'cyclone-type'}
                                                            style={{
                                                                backgroundColor: WIND_COLORS[windCategory(value)],
                                                                color: ['B','D','IC','VI'].includes(wind_categories[windCategory(value)].short) ? 'white' : '',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {wind_categories[windCategory(value)].short}
                                                        </span>
                                                    }*/}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </HtmlTooltip>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
