import {ADM0NAME_TO_FLAG, CAT_KNOTS, URL_ROOT} from "../constants/constants";

export function isPast(array) {
    return array.id.includes('past')
}

export function isFcst(array) {
    return array.id.includes('fcst')
}

export function getFlagCountry(adm0_name) {
    return URL_ROOT + `public/img/${ADM0NAME_TO_FLAG[adm0_name].toLowerCase()}.svg`;
}

export function windCategory(v) {
    let index = CAT_KNOTS.findIndex(x => x > v)
    return index > 0 ? index - 1 : CAT_KNOTS.length - 1
}

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
