import {
    URL_ROOT,
    CAT_KNOTS,
    WIND_COLORS,
    MAX_ZOOM,
    MIN_ZOOM,
    WIND_GRADIENT_COLORS,
    SURGE_GRADIENT_COLORS, EXCESS_RAIN_GRADIENT_COLORS
} from "../constants/constants";
import {getCenterBbox} from "./mapFunctions";

const LINE_OPACITY = 0.1
const CIRCLE_OPACITY = 0.2

export function showHideStormsThatHit(map, stormList, show, lineOpacity=null, circleOpacity=null) {
    console.log('Entered showStormsThatHit')
    stormList.map((storm) => {
        let layer = storm + '-layer'
        let layer_points = storm + '-points' + '-layer'
        let layer_name = storm + '-name' + '-layer'
        if (map.getLayer(layer)) {
            map.setLayoutProperty(layer, 'visibility', show ? 'visible' : 'none');
            if (lineOpacity) {
                map.setPaintProperty(layer, 'line-opacity', lineOpacity );
            }
        }
        if (map.getLayer(layer_points)) {
            map.setLayoutProperty(layer_points, 'visibility', show ? 'visible' : 'none');
            if (circleOpacity) {
                map.setPaintProperty(layer_points, 'circle-opacity', circleOpacity );
            }
        }
        if (map.getLayer(layer_name)) {
            map.setLayoutProperty(layer_name, 'visibility', show ? 'visible' : 'none');
        }
    })
}

export function hideAllStormsThatHit(map, stormList) {
    stormList.map((adm0) => {

        adm0.storms.map((storm) => {

            let layer = storm + '-layer'
            let layer_points = storm + '-points' + '-layer'
            let layer_name = storm + '-name' + '-layer'
            if (map.getLayer(layer)) {
                map.setPaintProperty(layer, 'line-opacity', LINE_OPACITY);
                map.setLayoutProperty(layer, 'visibility', 'none');
            }
            if (map.getLayer(layer_points)) {
                map.setPaintProperty(layer_points, 'circle-opacity', CIRCLE_OPACITY );
                map.setLayoutProperty(layer_points, 'visibility', 'none');
            }
            if (map.getLayer(layer_name)) {
                map.setLayoutProperty(layer_name, 'visibility', 'none');
            }
        })
    })
}

export function drawAllStormsThatHit(map, stormList) {
    console.log('Entered drawAllStormsThatHit')
    stormList.map((adm0) => {

        adm0.storms.map((storm) => {

            if (!map.getSource(storm + '-source')) {
                /// Adding source
                map.addSource(storm + '-source', {
                    'type': 'geojson',
                    'data': URL_ROOT + storm,
                })

                //// Past trajectory
                map.addLayer({
                    'id': storm + '-layer',
                    'type': 'line',
                    'source': storm + '-source',
                    'layout': {
                        'visibility': 'none',
                    },
                    'paint': {
                        'line-color': 'rgb(54,54,54)',
                        'line-width': 3,
                        'line-opacity': LINE_OPACITY
                    },
                    'filter': ['==', '$type', 'LineString'] //, ['==', 'ATCFID', 'SH082022']]
                });

                //// Past cyclone circles
                map.addLayer({
                    'id': storm + '-points' + '-layer',
                    'type': 'circle',
                    'source': storm + '-source',
                    'layout': {
                        'visibility': 'none',
                    },
                    'paint': {
                        'circle-radius': 5,
                        'circle-color': [
                            'case',
                            ['<', ['get', 'VMAX'], CAT_KNOTS[1]],
                            WIND_COLORS[0],
                            ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[1]], ['<', ['get', 'VMAX'], CAT_KNOTS[2]]],
                            WIND_COLORS[1],
                            ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[2]], ['<', ['get', 'VMAX'], CAT_KNOTS[3]]],
                            WIND_COLORS[2],
                            ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[3]], ['<', ['get', 'VMAX'], CAT_KNOTS[4]]],
                            WIND_COLORS[3],
                            ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[4]], ['<', ['get', 'VMAX'], CAT_KNOTS[5]]],
                            WIND_COLORS[4],
                            ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[5]], ['<', ['get', 'VMAX'], CAT_KNOTS[6]]],
                            WIND_COLORS[5],
                            ['>=', ['get', 'VMAX'], CAT_KNOTS[6]],
                            WIND_COLORS[6],
                            'rgba(54,54,54,0.5)'
                        ],
                        'circle-opacity': CIRCLE_OPACITY
                    },
                    'filter': ['==', '$type', 'Point']
                })

                //// Storm name
                map.addLayer({
                    'id': storm + '-name' + '-layer',
                    'type': 'symbol',
                    'source': storm + '-source',
                    'layout': {
                        'symbol-placement': 'line',
                        'text-font': ['Open Sans Regular'],
                        'text-field': [
                            'case',
                            ['!=', ['get', 'NAME'], 'NONAME'],
                            ['get', 'NAME'],
                            ''
                        ],
                        "text-size": 16,
                        'text-anchor': 'top',
                        'visibility': 'none',
                    },
                    'filter': ['all', ['==', '$type', 'LineString']]
                });


                /*                map.addLayer({
                                    'id': storm + '-layer',
                                    'type': 'line',
                                    'source': storm + '-source',
                                    'layout': {
                                        'visibility': 'none',
                                    },
                                    'paint': {
                                        'line-color': 'rgba(54,54,54,0.1)',
                                        'line-width': 1,
                                    },
                                    'filter': ['all', ['==', '$type', 'LineString'], ['==', 'TECH', 'TRAK']]
                                });

                                map.addLayer({
                                    'id': storm + '-points' + '-layer',
                                    'type': 'circle',
                                    'source': storm + '-source',
                                    'layout': {
                                        'visibility': 'none',
                                    },
                                    'paint': {
                                        'circle-radius': ['/',['get', 'VMAX'],10],
                                        'circle-color': [
                                            'interpolate',
                                            ['linear'],
                                            ['get', 'VMAX'],
                                            // Solution A
                                            20.0,
                                            ['to-color','#01c4ff'],
                                            63.0,
                                            ['to-color','#fff100'],
                                            115.0,
                                            ['to-color','#ff2400'],
                                        ],
                                        'circle-opacity': 0.2
                                    },
                                    'filter': ['==', '$type', 'Point']
                                });*/
            }

        })

    })
}

export function removeStormsThatHit(map, stormList) {
    console.log('Removing ...')
    stormList.map((storm, i) => {
        const name = 'stormsThatHit' + i

        console.log('Removing ', name)
        /// Removing source
        map.removeSource(name)
    })
}

export function drawStormsThatHit(map, stormList) {
    stormList.map((storm, i) => {
        const name = 'stormsThatHit' + i
        //const name = uuid()

        /// Adding source
        console.log('Adding ', name)
        map.addSource(name + '-source', {
            'type': 'geojson',
            'data': URL_ROOT + storm,
        })

        map.addLayer({
            'id': name + '-layer',
            'type': 'line',
            'source': name + '-source',
            'layout': {
                'visibility': 'visible',
            },
            'paint': {
                'line-color': 'rgba(54,54,54,0.5)',
                'line-width': 3,
            },
            'filter': ['all', ['==', '$type', 'LineString'], ['==', 'TECH', 'TRAK']]
        });

    })
}

export function updateSourceStorm(map, storm) {
    map.getSource('latest-source-nc').setData(storm.nc);
    map.getSource('latest-source').setData(storm.shp);
}

export function addSourceStorm(map, storm) {
    //// Adding real time nc data
    map.addSource('latest-source-nc', {
        'type': 'geojson',
        'data': storm.nc,
        //'tolerance': 0.5,
        'buffer': 0,
        maxzoom: MAX_ZOOM
    });

    //// Adding source for the latest data
    map.addSource('latest-source', {
        'type': 'geojson',
        'data': storm.shp,
    });

}

export function showHideStorm(map, storm, layers, show) {
    for (const [layerId, isVisible] of Object.entries(layers)) {
        if (map.getLayer(layerId)) {
            map.setLayoutProperty(layerId, 'visibility', (isVisible && show) ? 'visible' : 'none')
        }
    }

    for (const layerId of ['storm-name-layer', 'storm-icons-layer', 'storm-past-trajectory-layer', 'storm-fcst-trajectory-layer']) {
        if (map.getLayer(layerId)) {
            map.setLayoutProperty(layerId, 'visibility', show ? 'visible' : 'none')
        }
    }
}

export function drawStorm(map, storm, currentStorm) {

    //// Past wind
    map.addLayer({
        'id': 'pastPeakWind',
        'type': 'fill',
        'source': 'latest-source-nc',
        'layout': {
            'visibility': 'visible',
            //'visibility': storm.type === 'current' ? 'none' : 'visible',
        },
        'paint': {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                0,
                'rgba(255,255,255,0)',
                WIND_GRADIENT_COLORS[0].value,
                WIND_GRADIENT_COLORS[0].color, //'rgba(1,196,255,0.5)',
                WIND_GRADIENT_COLORS[1].value,
                WIND_GRADIENT_COLORS[1].color, //'#fdfdfd',
                WIND_GRADIENT_COLORS[2].value,
                WIND_GRADIENT_COLORS[2].color, //'#ff2400'
            ],
            'fill-opacity': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                0,
                0,
                WIND_GRADIENT_COLORS[0].value,
                0.5,
                WIND_GRADIENT_COLORS[1].value,
                0.7,
                WIND_GRADIENT_COLORS[2].value,
                0.9
            ],
            //'fill-antialias': true,
        },
        'filter': ['==', 'field', 'past_peak_wind']
    });

    //// Forecasted wind
    map.addLayer({
        'id': 'storm-fcst-wind-layer',
        'type': 'fill',
        'source': 'latest-source-nc',
        'layout': {
            'visibility': storm.type === 'current' ? 'visible' : 'none',
        },
        'paint': {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                WIND_GRADIENT_COLORS[0].value,
                WIND_GRADIENT_COLORS[0].color, //'rgba(1,196,255,0.5)',
                WIND_GRADIENT_COLORS[1].value,
                WIND_GRADIENT_COLORS[1].color, //'#fdfdfd',
                WIND_GRADIENT_COLORS[2].value,
                WIND_GRADIENT_COLORS[2].color, //'#ff2400'
/*                0,
                ['to-color','#01c4ff'],
                25,
                ['to-color','#fdfdfd'],
                50,
                ['to-color','#ff2400']*/
            ],
            'fill-opacity': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                0,
                0,
                WIND_GRADIENT_COLORS[0].value,
                0.5,
                WIND_GRADIENT_COLORS[1].value,
                0.7,
                WIND_GRADIENT_COLORS[2].value,
                0.9
            ],
            //'fill-antialias': true,
        },
        'filter': ['==', 'field', 'fcst_peak_wind']
    });

    //// Past wind
    map.addLayer({
        'id': 'pastPeakWater',
        'type': 'fill',
        'source': 'latest-source-nc',
        'layout': {
            'visibility': 'none',
        },
        'paint': {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                SURGE_GRADIENT_COLORS[0].value,
                SURGE_GRADIENT_COLORS[0].color,
                SURGE_GRADIENT_COLORS[1].value,
                SURGE_GRADIENT_COLORS[1].color,
                SURGE_GRADIENT_COLORS[2].value,
                SURGE_GRADIENT_COLORS[2].color,
            ],
            'fill-opacity': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                SURGE_GRADIENT_COLORS[0].value,
                0.3,
                SURGE_GRADIENT_COLORS[1].value,
                0.7,
                SURGE_GRADIENT_COLORS[2].value,
                0.9
            ],
            //'fill-antialias': true,
        },
        'filter': ['==', 'field', 'past_peak_water']
    });

    //// Past rain
/*    map.addLayer({
        'id': 'pastRainTotal',
        'type': 'fill',
        'source': 'latest-source-nc',
        'layout': {
            'visibility': storm.type === 'current' ? 'none' : 'visible',
        },
        'paint': {
            'fill-color': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                0,
                'rgba(9,1,255,0.5)',
                25,
                '#36fd07',
                50,
                '#ffea00'
            ],
            'fill-opacity': [
                'interpolate',
                ['linear'],
                ['get', 'val1'],
                0,
                0,
                5,
                0.5,
                50,
                0.9
            ],
            'fill-antialias': true,
        },
        'filter': ['==', 'field', 'past_rain_total']
    });*/


    //// Storm name
    map.addLayer({
        'id': 'storm-name-layer',
        'type': 'symbol',
        'source': 'latest-source',
        'layout': {
            'symbol-placement': 'line',
            'text-font': ['Open Sans Regular'],
            'text-field': ['get', 'NAME'],
            "text-size": 16,
            'text-anchor': 'top',
        },
        'filter': ['all', ['==', '$type', 'LineString']]
    });

    if (currentStorm) {
        //// Past trajectory
        map.addLayer({
            'id': 'storm-past-trajectory-layer',
            'type': 'line',
            'source': 'latest-source',
            'layout': {
                'visibility': 'visible',
            },
            'paint': {
                'line-color': 'rgba(54,54,54,0.5)',
                'line-width': 3,
            },
            'filter': ['all', ['==', '$type', 'LineString'], ['==', 'TECH', 'TRAK']] //, ['==', 'ATCFID', 'SH082022']]
        });

        //// Forecasted trajectory
        map.addLayer({
            'id': 'storm-fcst-trajectory-layer',
            'type': 'line',
            'source': 'latest-source',
            'layout': {
                'visibility': 'visible',
            },
            'paint': {
                'line-color': 'rgba(54,54,54,0.5)',
                'line-width': 3,
                'line-dasharray': [1, 2],
            },
            'filter': ['all', ['==', '$type', 'LineString'], ['==', 'TECH', 'FCST']]
        });
    } else {
        //// Past trajectory
        map.addLayer({
            'id': 'storm-past-trajectory-layer',
            'type': 'line',
            'source': 'latest-source',
            'layout': {
                'visibility': 'visible',
            },
            'paint': {
                'line-color': 'rgba(54,54,54,0.5)',
                'line-width': 3,
            },
            'filter': ['==', '$type', 'LineString']
        });
    }


    //// Cyclone circles
    map.addLayer({
        'id': 'storm-icons-layer',
        'type': 'circle',
        'source': 'latest-source',
        'layout': {
            'visibility': 'visible',
        },
        'paint': {
            'circle-radius': 5,
            'circle-color': [
                'case',
                ['<', ['get', 'VMAX'], CAT_KNOTS[1]],
                WIND_COLORS[0],
                ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[1]], ['<', ['get', 'VMAX'], CAT_KNOTS[2]]],
                WIND_COLORS[1],
                ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[2]], ['<', ['get', 'VMAX'], CAT_KNOTS[3]]],
                WIND_COLORS[2],
                ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[3]], ['<', ['get', 'VMAX'], CAT_KNOTS[4]]],
                WIND_COLORS[3],
                ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[4]], ['<', ['get', 'VMAX'], CAT_KNOTS[5]]],
                WIND_COLORS[4],
                ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[5]], ['<', ['get', 'VMAX'], CAT_KNOTS[6]]],
                WIND_COLORS[5],
                ['>=', ['get', 'VMAX'], CAT_KNOTS[6]],
                WIND_COLORS[6],
                'rgba(54,54,54,0.5)'
            ],
            'circle-opacity': 0.9,
            'circle-stroke-width': 1,
        },
        'filter': ['==', '$type', 'Point']
    })



    //// DO NOT REMOVE !!!!!!!! //////

    /*    //// Past cyclone icons
        map.addLayer({
            'id': 'storm-past-icons-layer',
            'type': 'symbol',
            'source': 'latest-source',
            'layout': {
                'visibility': 'visible',
                'icon-image': [
                    'case',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[1]], ['<', ['get', 'VMAX'], CAT_KNOTS[2]]],
                    'cyclone_1_past',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[2]], ['<', ['get', 'VMAX'], CAT_KNOTS[3]]],
                    'cyclone_2_past',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[3]], ['<', ['get', 'VMAX'], CAT_KNOTS[4]]],
                    'cyclone_3_past',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[4]], ['<', ['get', 'VMAX'], CAT_KNOTS[5]]],
                    'cyclone_4_past',
                    ['>=', ['get', 'VMAX'], CAT_KNOTS[5]],
                    'cyclone_5_past',
                    'cyclone_1_past'
                ],
                'icon-size': CYCLONE_SIZE,
                'text-field': ['get', 'DTG'],
                'text-offset': [0,1],
                'text-anchor': 'top',
                'icon-allow-overlap': true,
                'text-allow-overlap': false
            },
            'filter': ['all', ['==', '$type', 'Point'], ['==', 'TECH', 'TRAK']]
        })

        //// Forecasted cyclone icons
        map.addLayer({
            'id': 'storm-fcst-icons-layer',
            'type': 'symbol',
            'source': 'latest-source',
            'layout': {
                'visibility': 'visible',
                'icon-image': [
                    'case',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[1]], ['<', ['get', 'VMAX'], CAT_KNOTS[2]]],
                    'cyclone_1_fcst',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[2]], ['<', ['get', 'VMAX'], CAT_KNOTS[3]]],
                    'cyclone_2_fcst',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[3]], ['<', ['get', 'VMAX'], CAT_KNOTS[4]]],
                    'cyclone_3_fcst',
                    ['all', ['>=', ['get', 'VMAX'], CAT_KNOTS[4]], ['<', ['get', 'VMAX'], CAT_KNOTS[5]]],
                    'cyclone_4_fcst',
                    ['>=', ['get', 'VMAX'], CAT_KNOTS[5]],
                    'cyclone_5_fcst',
                    'cyclone_1_fcst'
                ],
                'icon-size': CYCLONE_SIZE,
                'text-field': ['get', 'DTG'],
                'text-offset': [0,1],
                'text-anchor': 'top',
                'icon-allow-overlap': true,
                'text-allow-overlap': false
            },
            'filter': ['all', ['==', '$type', 'Point'], ['==', 'TECH', 'FCST']]
        })*/

}

export function showHideExcessRain(map, show) {
    for (const layerId of ['excessRain']) {
        if (map.getLayer(layerId)) {
            map.setLayoutProperty(layerId, 'visibility', show ? 'visible' : 'none')
        }
    }
}

export function addCustomGeoJSONLayer(map, name, urlGeoJSON, field, colorValueScale, show=false) {
    ///// Adding custom GeoJSON layer
    map.addSource(`${name}-source`, {
        'type': 'geojson',
        'data': urlGeoJSON,
        'buffer': 0,
    });

    let fillColor = [
        'interpolate',
        ['linear'],
        ['get', field],
    ]

    for (let i = 0; i < colorValueScale.length; i++) {
        fillColor.push(colorValueScale[i].value)
        fillColor.push(colorValueScale[i].color)
    }

    ///// Excess Rain
    map.addLayer({
        'id': `${name}`,
        'type': 'fill',
        'source': `${name}-source`,
        'layout': {
            'visibility': show ? 'visible' : 'none',
        },
        'paint': {
            'fill-color': fillColor,
            'fill-opacity': [
                'interpolate',
                ['linear'],
                ['get', field],
                0,
                0,
                colorValueScale[0].value,
                0.7,
                colorValueScale[1].value,
                0.9,
            ],
            'fill-antialias': false,
        }
    });
}
