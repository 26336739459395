// CALCULATION OF PAYOUT
// ---------------------
//
// The calculation of the MCLD (Modelled Cyclone Losses and Damages) payment amount is enumerated below using the following formula:
//     P = min (L, y * x), where x = min (EP - AP, max (MCLD - AP, 0))
// where:
//     P	MCLD Payment Amount
// L	Aggregate Limit
// AP	Attachment Point
// EP	Exhaustion Point
// x	The amount by which the MCLD exceeds the Attachment Point (AP) (which amount shall not be greater than the Exhaustion Point (EP) minus the Attachment Point)
// y	Ceding Percentage
// From Exhibit B to the Policy:
//     L	$9,890,026
// AP	$17,562,830
// EP	$2,704,142,526
// y	0,37%
// x = min ($2,704,142,526 - $17,562,830; max ($226,478 - $17,562,830; 0))
// x = $0
// P = min ($9,890,026; 0,37% * $0)
// P = $0
// The MCLD Payment Amount is: $0 USD.
// (Zero dollars)


export function payout(L, y, EP, AP, MCLD) {
    return Math.round(Math.max(Math.min(L, y / 100 / 100 * x(EP, AP, MCLD)), 0)) // added Math.max to keep it positive
}

function x(EP, AP, MCLD) {
    return Math.round(Math.min(EP - AP, Math.max(MCLD - AP, 0)))
}

