import  React, { useState , useEffect, useRef, useCallback } from 'react';
import {Divider, Paper} from "@mui/material";
import { styled } from '@mui/material/styles';
import {
    subtractDays,
    subtractHours,
    subtractSeconds,
    getSecondsDiff,
    TODAY_DATE,
    setHoursMinuteSeconds, formatDate
} from "../utils/dateFunctions";
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import {Button} from "@material-ui/core";
import {getPathPPMs} from "../utils/nasaWeatherFunctions";
import CloseButton from "./closeButton";
import Draggable from "react-draggable";
import Legend from "./legend";
import {EXCESS_RAIN_GRADIENT_COLORS} from "../constants/constants";
import {useTranslation} from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export default function TimeAnimation(props) {

    const { t } = useTranslation();

    const {days, map, openTimeline, getPathFn, datasetName} = props
    const TODAY = new Date();
    const fcst = days < 0;
    const startDate = !fcst ? setHoursMinuteSeconds(subtractDays(days, TODAY), 3) : TODAY;
    const endDate = fcst ? setHoursMinuteSeconds(subtractDays(days, TODAY), 23) : TODAY;
    const secondsDiff = getSecondsDiff(startDate, endDate);

    const [date,setDate] = useState(startDate);
    const [progress,setProgress] = useState(0);
    const [currentImagePast, setCurrentImagePast] = useState(0);
    const [count, setCount] = useState(0);
    const [path, setPath] = useState(null);
    const [open, setOpen] = useState(openTimeline)

    const handleClose = () => {
        setOpen(false);
    };
    //const [currentPath, setCurrentPath] = useState('');

    const pathList = getPathFn(Math.abs(days))

    const [play, pause] = useInterval(() => {
        let paths
        // Your custom logic here
        if (map.current.getSource(`${datasetName}-source`)) {
            if (date >= endDate) {
                setDate(date => startDate)
            }

            //////
            //TODO: the pause button does not have the same effect on the fcst series
            if (fcst) {
                let hours_elapsed = Math.floor(getSecondsDiff(startDate, date) / (3 * 60 * 60))
                paths = pathList.filter(p => p.slice(-17, -8) <= `${String(hours_elapsed).padStart(3, '0')}h_${String(hours_elapsed+24).padStart(3, '0')}h`)
            } else {
                paths = pathList.filter(p => p.slice(-19, -8) <= `${formatDate(date)}_${String(date.getHours()).padStart(2, '0')}`)
            }

            if (path != paths[paths.length - 1]) {
                setPath(paths[paths.length - 1])
                map.current.getSource(`${datasetName}-source`).setData(path);
            }

            ////

            //console.log('map', map)
/*            if (count % 10800 == 0) {
                setCurrentImagePast(currentImagePast => (currentImagePast + 1) % (pathPPMs.length))
                setPathPPM(pathPPM => getPathPPM());
                map.current.getSource(`${datasetName}-source`).setData(getPathPPM());
            }*/

            setDate(date => subtractSeconds(-500*10, date));
            setProgress(100 - getSecondsDiff(date, endDate) / secondsDiff * 100);
            setCount(count => count + 500*10);
        }
    }, 150);

    function useInterval(callback, delay) {
        const savedCallback = useRef();
        const intervalId = useRef(null);
        const [currentDelay, setDelay] = useState(delay);

        const toggleRunning = useCallback(
            () => setDelay(currentDelay => (currentDelay === null ? delay : null)),
            [delay]
        );

        const clear = useCallback(() => clearInterval(intervalId.current), []);

        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }

            if (intervalId.current) clear();

            if (currentDelay !== null) {
                intervalId.current = setInterval(tick, currentDelay);
            }

            return clear;
        }, [currentDelay, clear]);

        return [toggleRunning, !!currentDelay];
    }

    function getPathPPM() {
        return pathList[currentImagePast]
    }

    // Creating arrays of days
    const daysArray = ['... | ']
    const endDay = fcst ? -1 : 0;
    for (let d=Math.abs(days)-1; d>endDay; d--) {
        let dayPast = subtractDays(d, endDate)
        daysArray.push(dayPast.toLocaleDateString("en-US", {weekday: "short",}))
        daysArray.push(' | ')
    }
    daysArray.push('...')

    //className={'timeAnimation'}

    return(
        <div>
            <Draggable>
                <div className='overlay' style={{ bottom: '5vh', right: '35vw', width: '30vw'}}>
                    {open &&
                        <Paper
                            elevation={15}
                            sx={{
                                backgroundColor: 'transparent', // 'rgba(234,234,234,0.72)',
                            }}
                        >
                            <CloseButton handleClose={handleClose} />
                            <Box sx={{ width: '100%', display: 'inline-block'}}>
                                <div style={{float: 'left', verticalAlign: 'center'}}>
                                    {pause
                                        ? <Button>
                                            <PauseCircleFilledIcon onClick={play} />
                                        </Button>
                                        : <Button>
                                            <PlayCircleFilledIcon onClick={play} />
                                        </Button>
                                    }
                                </div>
                                {/*<LinearProgress variant="determinate" value={progress} />*/}
                                <div style={{textAlign: 'center'}}>
                                    {/*                        <p>{currentImagePast}</p>
                        <p>{pathPPM}</p>
                        <p>{startDate.toLocaleString()}</p>*/}
                                    <p> {date.toLocaleDateString("en-US", {
                                        weekday: "long",
                                        //year: "numeric",
                                        day: "numeric",
                                        //month: "short",
                                    })}, {date.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"})}</p>
                                    <BorderLinearProgress variant="determinate" value={progress} />
                                    <div className='daysTimeline'>
                                        {daysArray.map(function(day) {
                                            return <span style={{textAlign: 'center'}}>{day}</span>;
                                        })
                                        }
                                    </div>
                                </div>
                            </Box>
                            <div>
                                <Divider />
                                <Legend
                                    legendTitle={`${t('buttons.exploration.menu.excessRain.title')} (mm)`}
                                    colorStops={EXCESS_RAIN_GRADIENT_COLORS}
                                    id={'excessRain'}
                                />
                            </div>
                        </Paper>
                    }
                </div>
            </Draggable>
        </div>
    )
}
