import React, {useState, useLayoutEffect, useRef} from "react";
import {KNTS2KMH} from "../constants/constants";

// Make component accept props and add default color
export default function Legend({legendTitle, colorStops, id, conversionFactor= 1.00, round=true}) {

    const ref = useRef(null);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(50);

    useLayoutEffect(() => {
        setWidth(ref.current.clientWidth); // ref.current.clientWidth
        //setHeight(ref.current.offsetHeight);
    }, [])

    // getting range of values
    const initValue = colorStops[0].value
    const finalValue = colorStops[colorStops.length-1].value
    const rangeValues = finalValue - initValue

    // setting layout dimensions
    const dims = {
        legend: {
            y: height * 0.25
        },
        rect: {
            y: height * 0.35,
            height: height * 0.4,
            width: width * 0.95
        },
        ticks: {
            y : height
        },
        x: 0,
        fontSize: height * 0.25,
    }

    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width='100%'
            height='100%'
            viewBox={`-${width*0.05} 0 ${width*1.05} ${height}`}
        >
            <defs>
                <linearGradient
                    id={`linearGradient-${id}`}
                    x1="0%"
                    x2="100%"
                    y1="0%"
                    y2="0%"
                >
                    {/* Set gradient color */}
                    {colorStops.map((value, index) => {
                        return (
                            <stop
                                offset={`${index * 100 / (colorStops.length - 1)}%`}
                                //offset={`${(value.value - initValue) * 100 / rangeValues}%`}
                                stopColor={value.color}>
                            </stop>
                        )
                    })}
                </linearGradient>
            </defs>
            <text
                x={dims.x}
                y={dims.legend.y}
                textAnchor="left"
                fontSize={dims.fontSize}
            >
                {legendTitle}
            </text>
            <rect
                width={dims.rect.width}
                height={dims.rect.height}
                x={dims.x}
                y={dims.rect.y}
                style={{fill: `url(#linearGradient-${id})`}} // "url(#linearGradient-1)"
                text-anchor="middle"
                //fill={"url(#linearGradient-1)"}
            />
            {colorStops.map((value, index) => {
                return (
                    <text
                        //x={dims.x + (index / (colorStops.length - 1)) * dims.rect.width}
                        //x={dims.x + index !== 0 ? (value.value / colorStops[colorStops.length-1].value) * dims.rect.width : 0}
                        //x={dims.x + ((value.value - initValue) / rangeValues) * dims.rect.width}
                        x={dims.x + (index / (colorStops.length - 1)) * dims.rect.width}
                        y={dims.ticks.y}
                        textAnchor="middle"
                        fontSize={dims.fontSize}
                    >
                        {round ? (value.value * conversionFactor).toFixed() : value.value * conversionFactor}
                    </text> // x={`${index*100/colorStops.length*width}`}
                )
            })}
        </svg>
    );
}
