export function capitalize1stLetter(str){
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function padWithChars(str, length, char){
    return str + char.repeat(length - str.length)
}

/*export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}*/

export function numberWithCommas(x, prefix='') {
    return prefix + Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function convertDMS( lat, lng ) {

    var convertLat = Math.abs(lat);
    var LatDeg = Math.floor(convertLat);
    var LatMin = (Math.floor((convertLat - LatDeg) * 60));
    var LatCardinal = ((lat > 0) ? "N" : "S");

    var convertLng = Math.abs(lng);
    var LngDeg = Math.floor(convertLng);
    var LngMin = (Math.floor((convertLng - LngDeg) * 60));
    var LngCardinal = ((lng > 0) ? "E" : "W");

    return LatDeg + '.' + LatMin + LatCardinal + " , " + LngDeg + '.' + LngMin + LngCardinal;
}
