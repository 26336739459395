import React, {useContext, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import { Amplify } from 'aws-amplify';

import {DataContext} from "../contexts/DataContext";

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
Amplify.configure(awsExports);

export default function Authentication() {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div className={'authentication'}>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Authenticator>
                    {({ signOut, user }) => (
                        <main>
                            <h1>Hello {user.username}</h1>
                            <button onClick={signOut}>Sign out</button>
                        </main>
                    )}
                </Authenticator>
            </Modal>
        </div>
    );
};
