import React, {useContext, useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import MuiInput from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import RiskParametersSlider from "./riskParametersSlider";
import {DataContext} from "../contexts/DataContext";
import RiskParametersInput from "./riskParametersInput";
import {useTranslation} from "react-i18next";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function SelectSettings() {

    const [value, setValue] = useState(30);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    };


    const {
        attachmentPoint,
        updateAttachmentPoint,
        aggregateLimit,
        updateAggregateLimit,
        cedingPercentage,
        updateCedingPercentage,
        exhaustionPoint,
        updateExhaustionPoint
    } = useContext(DataContext);

    const { t } = useTranslation();



    return (
        <Box>
            <RiskParametersInput name={t('settings.payout.attachment_point')} percent={false} initialValue={attachmentPoint} updateValue={updateAttachmentPoint}/>
            <RiskParametersInput name={t('settings.payout.coverage_limit')} percent={false} initialValue={aggregateLimit} updateValue={updateAggregateLimit}/>
            <RiskParametersInput name={`${t('settings.payout.ceding_percentage')} (in %)`} percent={true} initialValue={cedingPercentage} updateValue={updateCedingPercentage}/>
            <RiskParametersInput name={t('settings.payout.exhaustion_point')} percent={false} initialValue={exhaustionPoint} updateValue={updateExhaustionPoint}/>
        </Box>
    );
}



