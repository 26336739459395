// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { RiskParameter } = initSchema(schema);

export {
  RiskParameter
};