export const URL_ROOT = 'https://raw.githubusercontent.com/bertranddelvaux/data-web-tc/main/';

export const KNTS2KMH = 1.852

// wind speed categories (in knots)
export const CAT_KNOTS = [0,28,34,48,64,90,115];

export const WIND_TEXT = ['< 50 km/h', '51-62 km/h', '63-88 km/h', '89-117 km/h', '118-165 km/h', '166-212 km/h', '> 212 km/h']

// wind category colors
export const WIND_COLORS = [
    '#6533ff',
    '#0099fe',
    '#01ff66',
    '#ffcc01',
    '#ff9900',
    '#f00000',
    '#b3160c',
];

// wind gradient colors
export const WIND_GRADIENT_COLORS = [
    {value: 28 , color: 'rgba(1,196,255,0.5)'},
    {value: 34 , color: '#fdfdfd'},
    {value: 64 , color: '#ff2400'},
    {value: 115 , color: '#b3160c'}
]

// surge gradient colors
export const SURGE_GRADIENT_COLORS = [
    {value: 0 , color: 'rgba(1,43,255,0.5)'},
    {value: 1 , color: '#b119ee'},
    {value: 7 , color: '#fc02ac'}
]

// excess rain gradient colors
/*export const EXCESS_RAIN_GRADIENT_COLORS = [
    {value: 3, class: 1 , color: '#aaffee'},
    {value: 7.5, class: 2 , color: '#aa007f'},
    {value: 17.5, class: 3 , color: '#aa00bb'},
    {value: 37.5, class: 4 , color: '#aa0099'},
    {value: 75, class: 5 , color: '#aa0033'},
    {value: 150, class: 6 , color: '#aacc00'},
    {value: 300, class: 7 , color: '#aaaa00'}
]*/

export const EXCESS_RAIN_GRADIENT_COLORS = [
    {value: 25, color: '#647ce3'},
    {value: 51, color: '#63e3c8'},
    {value: 102, color: '#b3e363'},
    {value: 152, color: '#e2e263'},
    {value: 203, color: '#c78542'},
    {value: 254, color: '#a13231'},
    {value: 305, color: '#f91314'},
    {value: 406, color: '#feafb9'},
    {value: 508, color: '#ef01c6'},
    {value: 610, color: '#a8008b'},
]

export const LANDSLIDE_GRADIENT_COLORS = [
    {value: 1, color: '#ffcc5f'},
    {value: 2, color: '#f03b20'},
]

// population gradient colors
export const POPULATION_GRADIENT_COLORS = [
    {value: 10000 , color: '#7f47c4'},
    {value: 500000 , color: '#fff100'},
    {value: 2500000 , color: '#e56210'}
]

// value gradient colors
export const VALUE_GRADIENT_COLORS = [
    {value: 500000 , color: '#01c4ff'}, // 396433
    {value: 250000000 , color: '#fff100'}, // 250000000
    {value: 1000000000 , color: '#ff2400'} // 1295198912
]

// stochastic heatmap gradient colors
export const HEATMAP_GRADIENT_COLORS = [
    {value: 0 , color: 'rgba(33,102,172,0)'},
    {value: 0.2 , color: 'rgba(103,169,207,0.3)'},
    {value: 0.4 , color: 'rgba(209,229,240,0.4)'},
    {value: 0.6 , color: 'rgba(253,219,199,0.5)'},
    {value: 0.8 , color: 'rgba(239,138,98,0.6)'},
    {value: 1 , color: 'rgba(178,24,43,0.7)'},

]

// stochastic trajectories gradient colors
export const TRAJECTORIES_GRADIENT_COLORS = [
    {value: 25 , color: '#f6ea08'},
    {value: 70 , color: '#f69708'},
    {value: 100 , color: '#f64f08'},
]

// former wind category colors
/*export const WIND_COLORS = [
    '#babe03',
    '#eba63f',
    '#d94d09',
    '#bc1b1b',
    '#a01616'
];*/

// cyclone categories
export const WIND_CATEGORIES = [
    'Disturbance',
    'Tropical depression',
    'Moderate tropical storm',
    'Severe tropical storm',
    'Tropical cyclone',
    'Intense tropical cyclone',
    'Very intense tropical cyclone',
];

// cyclone categories (with short name)
export const wind_categories = [
    {name: 'Disturbance', short: 'B'},
    {name: 'Tropical depression', short: 'D'},
    {name: 'Moderate tropical storm', short: 'S'},
    {name: 'Severe tropical storm', short: 'SS'},
    {name: 'Tropical cyclone', short: 'C'},
    {name: 'Intense tropical cyclone', short: 'IC'},
    {name: 'Very intense tropical cyclone', short: 'VC'},
];

// cyclone icons
export const ICONS = [
    {id: 'cyclone_1_past', url: URL_ROOT + 'public/img/tc_icon_1_past_256.png'},
    {id: 'cyclone_1_fcst', url: URL_ROOT + 'public/img/tc_icon_1_fcst_256.png'},
    {id: 'cyclone_2_past', url: URL_ROOT + 'public/img/tc_icon_2_past_256.png'},
    {id: 'cyclone_2_fcst', url: URL_ROOT + 'public/img/tc_icon_2_fcst_256.png'},
    {id: 'cyclone_3_past', url: URL_ROOT + 'public/img/tc_icon_3_past_256.png'},
    {id: 'cyclone_3_fcst', url: URL_ROOT + 'public/img/tc_icon_3_fcst_256.png'},
    {id: 'cyclone_4_past', url: URL_ROOT + 'public/img/tc_icon_4_past_256.png'},
    {id: 'cyclone_4_fcst', url: URL_ROOT + 'public/img/tc_icon_4_fcst_256.png'},
    {id: 'cyclone_5_past', url: URL_ROOT + 'public/img/tc_icon_5_past_256.png'},
    {id: 'cyclone_5_fcst', url: URL_ROOT + 'public/img/tc_icon_5_fcst_256.png'},
];

// country flags
export const ADM0NAME_TO_FLAG = {
    'Comoros': 'Comoros',
    'Madagascar': 'Madagascar',
    'Mayotte': 'Mayotte',
    'Mauritius': 'Mauritius',
    'Mozambique': 'Mozambique',
    'Réunion' : 'Reunion',
    'Seychelles': 'Seychelles',
    'United Republic of Tanzania': 'Tanzania',
}

// a few layout constants
export const CYCLONE_SIZE = 0.1;  // size of cyclone icon
export const CYCLONE_LEGEND_SIZE = 20;  // size of cyclone icon size
export const MIN_ZOOM = 2;
export const MAX_ZOOM = 12;

// number of days after the end of a storm for the payout/simulation to be available
export const DAYS_AFTER_STORM = 30

// bbox for NASA's IMERG subset
export const BBOX = '-13.36,-41.3,99.14,3.79'

// flood suffix to exclude from the risk parameters
export const FLOOD_SUFFIX = '_flood'
