import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useTranslation, Trans } from 'react-i18next';
import IconButton from "@material-ui/core/IconButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {Divider, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SelectAdmin from "./selectAdmin";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import SelectLayers from "./selectLayers";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import SelectColor from "./selectColor";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Authentication from "./authentication";
import SelectSettings from "./selectSettings";
import {makeStyles} from "@material-ui/core/styles";
import CloseButton from "./closeButton";

const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français' },
    pt: { nativeName: 'Português'}
};

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.9
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '30vw',
        height: '60vh',
        overflow:'scroll'
    },
}));


export default function Languages(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const { t, i18n } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={'languages'}>
            <IconButton>
                <LanguageOutlinedIcon className={'icon-settings'} fontSize={'large'} color={'primary'} onClick={handleOpen} />
            </IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <CloseButton handleClose={handleClose}/>
                        {Object.keys(lngs).map((lng) => (
                            <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                {lngs[lng].nativeName}
                            </button>
                        ))}
                    </div>
                </Fade>
            </Modal>





        </div>
    );
}
