import React, {Component, useState, createContext, useEffect} from 'react';
import {URL_ROOT, WIND_COLORS} from '../constants/constants'

export const DataContext = createContext();

const DataContextProvider = (props) => {
    const [data, setData] = useState(
        {
            storm: null,
            selectedStorm: null,
            currentStorm: false,
            pastStorm: null,
            current_storm: null,
            pastPeakWind: true,
            pastPeakWater: false,
            pastRainTotal: false,
            accRain3h: false,
            accRain1d: false,
            fcstRain1d: false,
            fcstRain5d: false,
            accRain1dVideo: true,
            fcstRain1dVideo: false,
            storm_position_trajectory: true,
            storm_position_points: true,
            storm_position_labels: true,
            adm0: false,
            adm1: false,
            adm2: false,
            population: false,
            value: false,
            stochastic_heatmap: false,
            stochastic_trajectories: false,
            latest_storms: [],
            url_root: URL_ROOT,
            wind_colors: WIND_COLORS,
            selectedDTG: null,
            stormsToShow: [],
            attachmentPoint: 0.00,
            aggregateLimit: 0.00,
            cedingPercentage: 0.00,
            exhaustionPoint: 0.00
        }
    )

    useEffect(() => {
        const shp = 'mpres_data/taos_swio_ofcl_storms_shp.geojson'
        fetch(URL_ROOT + 'latestStorms.json') //note here the lcoal path is relative to "index.html" instead of "index.js"
            .then(resp => resp.json())
            .then(resp => resp.tc_realtime)
            .then(resp => {
                if (resp.length === 0) {
                    throw new Error("Length of array tc_realtime = 0");
                } else {
                    return resp.filter(x => x.id.slice(0,2) === 'SH')
                }
            })
            .then(resp => resp.map(x => ({
            storm_name: x.storm_name,
            id: x.id,
            shp: URL_ROOT + shp,
            nc: x.jtwc ? URL_ROOT + x.jtwc : URL_ROOT + x.fmee,
            fmee: x.fmee ? URL_ROOT + x.fmee : URL_ROOT + x.jtwc,
            jtwc: x.jtwc ? URL_ROOT + x.jtwc : URL_ROOT + x.fmee,
            losses: URL_ROOT + x.losses,
            type: 'current',
            bbox: x.bbox,
        })))
            .then(resp => {
                setData({...data, selectedStorm:resp[0], currentStorm: true, current_storm: resp[0]})   // select only the first storm
            })
            .catch(function(err) {
                console.log('Warning: trying to fetch data for the latest storm', err)
                return null
            });

    }, [])


        return (
            <DataContext.Provider value={{
                ...data,
                updateStorm: (storm) => {
                    setData({...data, storm:storm})
                },
                updateSelectedStorm: (selectedStorm) => {
                    console.log('<DataContext> selectedStorm', selectedStorm ? selectedStorm.storm_name : 'no storm selected')
                    setData({...data, selectedStorm:selectedStorm})
                    console.log('<DataContext> selectedStorm', selectedStorm ? selectedStorm.storm_name : 'no storm selected')
                },
                updateCurrentStorm: (currentStorm) => {
                    setData({...data, currentStorm:currentStorm})
                },
                updatePastStorm: (pastStorm) => {
                    setData({...data, pastStorm:pastStorm})
                },
                updateCurrent_Storm: (current_storm) => {
                    setData({...data, current_storm:current_storm})
                },
                updatePastPeakWind: (pastPeakWind) => {
                    setData({...data, pastPeakWind:pastPeakWind})
                },
                updatePastPeakWater: (pastPeakWater) => {
                    setData({...data, pastPeakWater:pastPeakWater})
                },
                updatePastRainTotal: (pastRainTotal) => {
                    setData({...data, pastRainTotal:pastRainTotal})
                },
                updateAccRain3h: (accRain3h) => {
                    setData({...data, accRain3h:accRain3h})
                },
                updateAccRain1d: (accRain1d) => {
                    setData({...data, accRain1d:accRain1d})
                },
                updateFcstRain1d: (fcstRain1d) => {
                    setData({...data, fcstRain1d:fcstRain1d})
                },
                updateFcstRain5d: (fcstRain5d) => {
                    setData({...data, fcstRain5d:fcstRain5d})
                },
                updateAccRain1dVideo: (accRain1dVideo) => {
                    setData({...data, accRain1dVideo:accRain1dVideo})
                },
                updateFcstRain1dVideo: (fcstRain1dVideo) => {
                    setData({...data, fcstRain1dVideo:fcstRain1dVideo})
                },
                updateStormPositionTrajectory: (storm_position_trajectory) => {
                    setData({...data, storm_position_trajectory:storm_position_trajectory})
                },
                updateStormPositionPoints: (storm_position_points) => {
                    setData({...data, storm_position_points:storm_position_points})
                },
                updateStormPositionLabels: (storm_position_labels) => {
                    setData({...data, storm_position_labels:storm_position_labels})
                },
                updateAdm0: (adm0) => {
                    setData({...data, adm0:adm0})
                },
                updateAdm1: (adm1) => {
                    setData({...data, adm1:adm1})
                },
                updateAdm2: (adm2) => {
                    setData({...data, adm2:adm2})
                },
                updatePopulation: (population) => {
                    setData({...data, population:population})
                },
                updateValue: (value) => {
                    setData({...data, value:value})
                },
                updateStochasticHeatmap: (stochastic_heatmap) => {
                    setData({...data, stochastic_heatmap:stochastic_heatmap})
                },
                updateStochasticTrajectories: (stochastic_trajectories) => {
                    setData({...data, stochastic_trajectories:stochastic_trajectories})
                },
                updateLatestStorms: (latest_storms) => {
                    setData({...data, latest_storms:latest_storms})
                },
                updateWindColors: (wind_colors) => {
                    setData({...data, wind_colors: wind_colors})
                },
                updateSelectedDTG: (selectedDTG) => {
                    setData({...data, selectedDTG: selectedDTG})
                },
                updateStormsToShow: (stormsToShow) => {
                    setData({...data, stormsToShow: stormsToShow})
                },
                updateAttachmentPoint: (attachmentPoint) => {
                    setData({...data, attachmentPoint: attachmentPoint})
                },
                updateAggregateLimit: (aggregateLimit) => {
                    setData({...data, aggregateLimit: aggregateLimit})
                },
                updateCedingPercentage: (cedingPercentage) => {
                    setData({...data, cedingPercentage: cedingPercentage})
                },
                updateExhaustionPoint: (exhaustionPoint) => {
                    setData({...data, exhaustionPoint: exhaustionPoint})
                }
            }}
            >
                {props.children}
            </DataContext.Provider>
        )
}

export default DataContextProvider;
