import React from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {Tooltip} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@mui/icons-material/Close';

const CustomIconButton = styled(IconButton)({
    padding: 0
})

export default function CloseButton(props) {

    const {handleClose} = props

    const { t } = useTranslation();

    return (
        <Tooltip placement={'top'} title={t(`buttons.exit.helper`)} className={'exit-button'}>
            <CustomIconButton>
                <CloseIcon fontSize={'medium'} color={'primary'} onClick={handleClose}/>
            </CustomIconButton>
        </Tooltip>
    )

}
