import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    layout: {
        //margin: 'auto',
        //marginLeft: 100,
        //paddingRight: 100
    }
})

export default function Layout({children}) {

    const classes = useStyles()

    return (
        <div className={classes.layout}>
            {children}
        </div>
    )
}