import * as React from 'react';
import MaterialTable from 'material-table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper'
import {useState} from "react";
import { useTranslation } from 'react-i18next';
import {WIND_TEXT} from "../constants/constants";

function numberWithCommas(x, prefix='') {
    return prefix + Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function populationExposed(population, wind_cat) {
    let pop = population

    if (wind_cat[0]) {
        pop -= wind_cat[0]
    }

/*    if (wind_cat[1]) {
        pop -= wind_cat[1]
    }*/

    return pop
}

function createData(id, adm0_name, adm1_name, adm2_name, loss, population, wind_cat, parentId) {

    let result = {
        id: id,
        adm0: adm0_name,
        adm1: adm1_name,
        adm2: adm2_name,
        loss: numberWithCommas(loss, '$'),
        pop: numberWithCommas(populationExposed(population, wind_cat)),
        //pop: numberWithCommas(wind_cat[0] ? wind_cat[1] ? population - (wind_cat[0] + wind_cat[1]) : population - wind_cat[0] : population), // BEWARE!! Only population from 63km/h onwards is considered as affected
        //wind_cat_0: wind_cat[0] ? numberWithCommas(wind_cat[0]) : '',
        wind_cat_1: wind_cat[1] ? numberWithCommas(wind_cat[1]) : '',
        wind_cat_2: wind_cat[2] ? numberWithCommas(wind_cat[2]) : '',
        wind_cat_3: wind_cat[3] ? numberWithCommas(wind_cat[3]) : '',
        wind_cat_4: wind_cat[4] ? numberWithCommas(wind_cat[4]) : '',
        wind_cat_5: wind_cat[5] ? numberWithCommas(wind_cat[5]) : '',
    }

    // Adding parentId if provided
    if (parentId) {
        result.parentId = parentId
    }

    return result

}


export default function ReportTableExport(props) {

    const {currentLosses, storm_name, storm_id} = props;

    const { t } = useTranslation();

    const [rows, setRows] = useState([]);

    for (const [i0, adm0] of currentLosses.entries()) {
        console.log((i0 + 1) * 100, ' -> pushing data for ', adm0.adm0_name)
        rows.push(createData((i0 + 1) * 100, adm0.adm0_name, '', '', adm0.loss, adm0.population, adm0.wind_cat, null))
        for (const [i1, adm1] of adm0.adm1.entries()) {
            console.log('  ', (i0 + 1) * 100 + (i1 + 1) * 10, ' -> pushing data for ', adm1.adm1_name)
            rows.push(createData((i0 + 1) * 100 + (i1 + 1) * 10,'', adm1.adm1_name, '', adm1.loss, adm1.population, adm1.wind_cat, (i0 + 1) * 100))
            for (const [i2, adm2] of adm1.adm2.entries()) {
                if (adm2.adm2_name !== 'Administrative unit not available') {
                    console.log('    ', (i0 + 1) * 100 + (i1 + 1) * 10 + (i2 + 1), ' -> pushing data for ', adm2.adm2_name)
                    rows.push(createData((i0 + 1) * 100 + (i1 + 1) * 10 + i2,'', '', adm2.adm2_name, adm2.loss, adm2.population, adm2.wind_cat, (i0 + 1) * 100 + (i1 + 1) * 10))
                }
            }
        }
        //console.log('test')
    }

    return (
        <TableContainer component={Paper}>
            <MaterialTable
                title={t('report.adm_breakdown.title')}
                columns={[
                    { title: 'Adm 0', field: 'adm0' },
                    { title: 'Adm 1', field: 'adm1' },
                    { title: 'Adm 2', field: 'adm2' },
                    { title: t('report.adm_breakdown.loss'), field: 'loss', type: 'numeric' },
                    { title: t('report.adm_breakdown.pop'), field: 'pop', type: 'numeric' },
                    //{ title: WIND_TEXT[0], field: 'wind_cat_0'},
                    { title: WIND_TEXT[2], field: 'wind_cat_1'},
                    { title: WIND_TEXT[3], field: 'wind_cat_2'},
                    { title: WIND_TEXT[4], field: 'wind_cat_3'},
                    { title: WIND_TEXT[5], field: 'wind_cat_4'},
                    { title: WIND_TEXT[6], field: 'wind_cat_5'},
                    //{ title: 'Wind cat', field: 'cat'},
                ]}
                data={rows}
                /*            data={[
                                { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
                                { name: 'Zerya Betul', surname: 'Baran', birthYear: 2017, birthCity: 34 },
                            ]}*/
                //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                options={{
                    exportButton: true,
                    sorting: false,
                    exportFileName: `report_${storm_id}_${storm_name}`,
                    pageSize: rows.length,
                    exportAllData: true,
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
            />
        </TableContainer>
    )
}
