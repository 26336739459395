import './App.css';
import * as React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {createTheme, ThemeProvider} from "@material-ui/core";
import SearchAppBar from "./components/searchAppBar";
import Layout from "./components/layout";
import MapContainer from "./components/mapContainer";
import DataContextProvider from './contexts/DataContext'
import PieChart from "./components/pieChart";
import PieChartHover from './components/pieChartHover'
import PieChartCountry from "./components/pieChartCountry";
import { Authenticator, View } from '@aws-amplify/ui-react';


const useStyles = makeStyles({
    app: {
        height: '100vh',
        width: '100vw',
    }
})

const theme = createTheme({
    typography: {
        fontFamily: 'Comfortaa',
        fontWeightRegular: 600
    }
})

export default function App() {
    const classes = useStyles()

    return (
        <Authenticator.Provider>
            <DataContextProvider>
                <ThemeProvider theme={theme}>
                    <div className={classes.app}>
                        {/*<SearchAppBar title={'Tropical Cyclone Platform'} layersName={'layers'}/>*/}
                        {/*<MapContainer />*/}
                        <MapContainer />
                        {/*<EnhancedTable />*/}
                        {/*<PieChartCountry />*/}
                        {/*<PieChart />*/}
                        {/*<PieChartHover />*/}
                        {/*<ChartContainer />*/}
                        {/*<ExposureTable2 />*/}
                    </div>
                </ThemeProvider>
            </DataContextProvider>
        </Authenticator.Provider>
    );
}
