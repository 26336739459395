import React, { PureComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ComposedChart,
    Line,
    Label,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
} from 'recharts';
import {linearGradient, defs} from 'svg';

function numberWithCommas(x) {
    return '$' + Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function HistoricalChart(props) {

    const { t } = useTranslation();

    return (
        <ComposedChart
            width={600}
            height={300}
            data={props.data}
            margin={{
                top: 20,
                right: 60,
                bottom: 20,
                left: 10,
            }}
        >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorRv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="red" stopOpacity={0.9}/>
                    <stop offset="95%" stopColor="red" stopOpacity={0.7}/>
                </linearGradient>
            </defs>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="year" />  {/*scale="band"*/}
            <YAxis yAxisId="left" >
                <Label
                    style={{
                        textAnchor: "middle",
                        fontSize: "100%",
                        //fill: "white",
                    }}
                    angle={270}
                    value={t('adm_overlay.chart.left_axis.title')} />
            </YAxis>
            <YAxis yAxisId="right" orientation="right" tickFormatter={numberWithCommas}/>
            <Tooltip placement={'top'} formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
            <Legend />
{/*                <Bar yAxisId="right" dataKey="counter_year" barSize={20} fill="#413ea0" fillOpacity={0.2}/>
            <Scatter yAxisId="right" dataKey="counter_adm" fill="red" fillOpacity={0.7}/>*/}
            <Bar yAxisId="left" dataKey="counter_year" name={t('adm_overlay.chart.bottom_axis.storms_per_year.title')} barSize={10} fill="#8884d8" stroke="#8884d8" fill="url(#colorUv)"/>
            <Bar yAxisId="left"  dataKey="counter_adm" name={t('adm_overlay.chart.bottom_axis.storms_per_adm.title')} barSize={10} fill="#82ca9d" stroke="#82ca9d" fill="url(#colorPv)"/>
            <Area yAxisId="right" type="monotone" dataKey="loss" fill="red" stroke='red' fill="url(#colorRv)"/>
            {/*<Scatter dataKey="cnt" fill="red" />*/}
        </ComposedChart>
    );
}
