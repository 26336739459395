import React from 'react';
import {getFlagCountry} from "../utils/miscFunctions";


const formatNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
}).format;

function calculateTotal(pieChart) {
    return formatNumber(pieChart
        .getAllSeries()[0]
        .getVisiblePoints()
        .reduce((s, p) => s + p.originalValue, 0));
}


export default function TooltipTemplate(pieChart) {
    console.log('hello')
    const { adm0_name } = pieChart.getAllSeries()[0].getVisiblePoints()[0].data;
    console.log('hello country ', adm0_name)
    return (
        <svg>
            <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"></circle>
            {/*<image href={getFlagCountry(adm0_name)} x="70" y="58" width="60" height="40" />*/}
            <text textAnchor="middle" x="100" y="100" style={{ fontSize: 18, fill: '#494949' }}>
                <tspan x="100">{adm0_name}</tspan>
                <tspan x="100" dy="20px" style={{ fontWeight: 600 }}>{
                    calculateTotal(pieChart)
                }</tspan>
            </text>
        </svg>
    );
}
