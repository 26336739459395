// a few constants for today
export const TODAY_DATE = new Date()
export const THIS_YEAR = TODAY_DATE.getFullYear()
export const THIS_MONTH = String(TODAY_DATE.getMonth() + 1).padStart(2, '0'); //January is 0!
export const THIS_DAY = String(TODAY_DATE.getDate()).padStart(2, '0');

// DTG: previous and next
export const PREV_DTG = formatDate(subtractHours(6, TODAY_DATE))  // to make sure to be earlier than the previous point
export const NEXT_DTG = formatDate(subtractHours(-6, TODAY_DATE))  // to make sure to be later than the next forecasted point


export function getSecondsDiff(startDate, endDate) {
    const msInSecond = 1000;

    return Math.round(
        Math.abs(endDate - startDate) / msInSecond
    );
}

export function subtractSeconds(numOfSeconds, date = new Date()) {
    const newDate = new Date(date)
    newDate.setSeconds(date.getSeconds() - numOfSeconds);
    return newDate;
}

export function subtractHours(numOfHours, date = new Date()) {
    const newDate = new Date(date)
    newDate.setHours(date.getHours() - numOfHours);
    return newDate;
}

export function subtractDays(numOfDays, date = new Date()) {
    const newDate = new Date(date)
    newDate.setDate(date.getDate() - numOfDays);
    return newDate;
}

export function setHoursMinuteSeconds(date = new Date(), hours=0, minutes=0, seconds=0) {
    const newDate = new Date(date)
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(seconds)
    return newDate;
}

export function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function formatDate(date= new Date(), sep='') {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();

    return yyyy + sep + mm + sep + dd;
}

export function formatTime(date = new Date(), sep='') {
    let hh = String(date.getHours()).padStart(2, '0');
    let mm = String(date.getMinutes()).padStart(2, '0');
    let ss = String(date.getSeconds()).padStart(2, '0');
    return hh + sep + mm + sep + ss
}

export function dayOfYear(date=TODAY_DATE) {
    const yearStart = new Date(date.getFullYear(), 0, 0);
    const diff = date - yearStart;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}
