import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PieChartCountry from "./pieChartCountry";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@mui/material/Avatar";
import {
    Divider,
    FormHelperText,
    FormLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Tooltip
} from "@mui/material";
import { useAuthenticator } from '@aws-amplify/ui-react';
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from "@mui/material/FormControl";
import SelectAdmin from "./selectAdmin";
import SelectLayers from "./selectLayers";
import SelectColor from "./selectColor";
import Authentication from './authentication';
import SelectSettings from "./selectSettings";
import {useTranslation} from "react-i18next";
import SelectSettingsAdmin from "./selectSettingsAdmin";
import CloseButton from "./closeButton";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.9
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '30vw',
        height: '60vh',
        overflow:'scroll'
    },
}));
export default function Settings(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openSignOut, setOpenSignOut] = useState(false);
    const [openAdmin, setOpenAdmin] = useState(false);
    const [openLayers, setOpenLayers] = useState(false);
    const [openColors, setOpenColors] = useState(false);
    const [openPayout, setOpenPayout] = useState(false);
    const [openSettingsAdmin, setOpenSettingsAdmin] = useState(false);

    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setOpenAdmin(false);
        setOpenLayers(false);
        setOpenColors(false);
        setOpenPayout(false);
        setOpenSettingsAdmin(false);
    };

    const handleOpenSignOut = () => {
        setOpenSignOut(true);
    }

    const handleCloseSignOut = () => {
        setOpenSignOut(false);
    }

    const handleClickAdmin = () => {
        setOpenAdmin(!openAdmin);
    };

    const handleClickLayers = () => {
        setOpenLayers(!openLayers);
    };

    const handleClickColors = () => {
        setOpenColors(!openColors);
    };

    const handleClickPayout = () => {
        setOpenPayout(!openPayout);
    }

    const handleClickSettingsAdmin = () => {
        setOpenSettingsAdmin(!openSettingsAdmin);
    };

    return (
        <div className={'settings'}>
{/*            <IconButton onClick={handleOpen}>
                <Avatar sx={{ bgcolor: 'darkorange'}}>BD</Avatar>
            </IconButton>*/}
{/*            <IconButton onClick={handleOpen}>
                <SettingsOutlinedIcon />
            </IconButton>*/}
            <IconButton>
                <SettingsOutlinedIcon className={'icon-settings'} fontSize={'large'} color={'primary'} onClick={handleOpen} />
            </IconButton>
            {authStatus === 'authenticated' &&
                <IconButton className={'icon-settings'} onClick={handleOpenSignOut}>
                    <Avatar sx={{ bgcolor: 'darkorange'}}>{user.attributes.given_name.charAt(0)}{user.attributes.family_name.charAt(0)}</Avatar>
                </IconButton>
            }
            {/*<SettingsOutlinedIcon className={'icon-settings'} onClick={handleOpen} />*/}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSignOut}
                onClose={handleCloseSignOut}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openSignOut}>
                    <div className={classes.paper}>
                        <button onClick={signOut}>Sign out</button>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <CloseButton handleClose={handleClose} />
                        <h2>{t('settings.title')}</h2>
                        <Divider />
                        <List>
                            {/*TODO: UNCOMMENT TO SEE ADMIN BREAKDOWN*/}
{/*                            <ListItemButton onClick={handleClickAdmin}>
                                <ListItemIcon>
                                    <AssessmentOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('settings.admin.title')} />
                                {openAdmin ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                                <SelectAdmin />
                            </Collapse>*/}

                            {/*TODO: UNCOMMENT TO SEE LAYERS BREAKDOWN*/}
{/*                            <ListItemButton onClick={handleClickLayers}>
                                <ListItemIcon>
                                    <LayersOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('settings.layers.title')} />
                                {openLayers ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openLayers} timeout="auto" unmountOnExit>
                                <SelectLayers />
                            </Collapse>*/}

                            {/*TODO: UNCOMMENT TO SEE COLOR PICKING*/}
{/*                            <ListItemButton onClick={handleClickColors}>
                                <ListItemIcon>
                                    <PaletteOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('settings.colors.title')} />
                                {openColors ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openColors} timeout="auto" unmountOnExit>
                                <SelectColor />
                            </Collapse>*/}

{/*                            <ListItemButton>
                                <ListItemIcon>
                                    <SettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('settings.preferences.title')} />
                            </ListItemButton>*/}
                            <ListItemButton onClick={handleClickPayout}>
                                <ListItemIcon>
                                    <AdminPanelSettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('settings.payout.title')} />
                                {openPayout ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openPayout} timeout="auto" unmountOnExit>
                                {/*{authStatus !== 'authenticated' ? <Authentication /> : <SelectSettings />}*/}
                                {authStatus !== 'authenticated'
                                    ? <Authentication />
                                    : user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].some(e => ['simulation','payout','admin'].includes(e))
                                    ? <SelectSettings />
                                    : t('settings.payout.no_permission')
                                }
                            </Collapse>

                            {authStatus === 'authenticated'
                            && user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].some(e => ['admin'].includes(e))
                                &&
                            <ListItemButton onClick={handleClickSettingsAdmin}>
                                <ListItemIcon>
                                    <AdminPanelSettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('settings.settings_admin.title')} />
                                {openSettingsAdmin ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            }

                            <Collapse in={openSettingsAdmin} timeout="auto" unmountOnExit>
                                {authStatus === 'authenticated'
                                 && user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].some(e => ['admin'].includes(e))
                                    && <SelectSettingsAdmin />
                                }
                            </Collapse>

                            {/*{authStatus === 'authenticated' && <h6>Hello {user.attributes.given_name} {user.attributes.family_name} {user.attributes.email} part of group {user.signInUserSession.accessToken.payload["cognito:groups"]}</h6> }*/}
                        </List>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
