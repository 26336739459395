import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {Button} from "@mui/material";

import {DataStore} from "@aws-amplify/datastore";
import {RiskParameter} from "../models";
import {FLOOD_SUFFIX, URL_ROOT} from "../constants/constants";

async function saveNewRecord(newRecord) {
    await DataStore.save(
        newRecord
    );
}


export default function EditableTable() {

    const [columns, setColumns] = useState([
        { title: 'Country', field: 'country', validate: rowData => rowData.country < 0 ? 'Country name cannot be empty' : '' },
        { title: 'Year', field: 'year', initialEditValue: new Date().getFullYear(), type: 'numeric' },
        { title: 'Attachment Point', field: 'attachmentPoint', type: 'numeric', validate: rowData => rowData.attachmentPoint < 0 ? 'Please enter a positive value' : '' },
        { title: 'Aggregate Limit', field: 'aggregateLimit', type: 'numeric', validate: rowData => rowData.aggregateLimit < 0 ? 'Please enter a positive value' : '' },
        { title: 'Ceding Percentage', field: 'cedingPercentage', type: 'numeric', validate: rowData => rowData.cedingPercentage < 0 ? 'Please enter a positive value' : rowData.cedingPercentage > 100 ? 'The ceding percentage should not exceed 100%' : '', render: rowData => `${rowData.cedingPercentage}%` },
        { title: 'Exhaustion Point', field: 'exhaustionPoint', type: 'numeric', validate: rowData => rowData.exhaustionPoint < 0 ? 'Please enter a positive value' : '' },
    ]);



/*    async function updateRecord(id, newRecord) {
        const original = await DataStore.query(RiskParameter, id);
        await DataStore.save(
            RiskParameter.copyOf(original, updated => {
                updated.attachmentPoint = newRecord.attachmentPoint
                updated.aggregateLimit = newRecord.aggregateLimit
                updated.cedingPercentage = newRecord.cedingPercentage
                updated.exhaustionPoint = newRecord.exhaustionPoint
            })
        );
    }*/

/*    const [data, setData] = useState([
        { country: 'Madagascar', year: 2022, attachmentPoint: 10000, aggregateLimit: 1000000, cedingPercentage: 73, exhaustionPoint: 200000 },
        { country: 'Comoros', year: 2022, attachmentPoint: 1000, aggregateLimit: 100000, cedingPercentage: 37, exhaustionPoint: 200000 },
    ]);*/

    const [data, setData] = useState([])
    const [newData, setNewData] = useState([])
    const [flagNewData, setFlagNewData] = useState(false)

    //console.log(data)


    useEffect(() => {
        //console.log('Effect: init')
        fetchData().then(console.log('data fetched successfuly'))
        const subscription = DataStore.observe(RiskParameter).subscribe(() => fetchData())
/*        const subscription = DataStore.observe(RiskParameter).subscribe(msg => {
            //setData([...data, msg.element]);
            console.log(msg.element);
        });*/
        return () => subscription.unsubscribe()
    },[])

    useEffect(() => {
        //console.log('Effect: happens everytime data is being created/updated')
        if (newData.country) {
            //console.log('Effect: new data about to be uploaded')
            saveNewData(newData)
                .then(console.log('Effect: new data updated successfuly'))
        }
    }, [newData])

    async function fetchData() {
        const records = await DataStore.query(RiskParameter);

        // Filter records where "country" ends with "_flood"
        const filteredRecords = records.filter(item => !item.country.endsWith(FLOOD_SUFFIX));

        setData(filteredRecords);
    }


    async function saveNewData(newData) {

        if (newData.country !== '') {

            const newRecord = new RiskParameter({
                "country": newData.country,
                "year": newData.year, // new Date().getFullYear(),
                "attachmentPoint": newData.attachmentPoint,
                "aggregateLimit": newData.aggregateLimit,
                "cedingPercentage": newData.cedingPercentage,
                "exhaustionPoint": newData.exhaustionPoint
            })

            const riskParameters = await DataStore.query(RiskParameter, r => r.country("eq", newData.country).year("eq", newData.year))

            if (riskParameters.length > 0) {
                // if the country is already in the database, take the id and replace it with the new value
                const id = riskParameters[0].id
                //await updateData(newRecord).then(console.log('updated data', newRecord))
            } else {
                await saveNewRecord(newRecord).then(console.log('saved new data', newRecord))
            }

        }

    }

    async function deleteData(oldData) {
        await DataStore.delete(RiskParameter, r => r
            .country("eq", oldData.country)
            .year("eq", oldData.year)
            .attachmentPoint("eq", oldData.attachmentPoint)
            .aggregateLimit("eq", oldData.aggregateLimit)
            .cedingPercentage("eq", oldData.cedingPercentage)
            .exhaustionPoint("eq", oldData.exhaustionPoint))
    }


    return (
        <div>
            <MaterialTable
                title="Risk Parameters"
                columns={columns}
                data={data.map(e => ({
                    'country': e.country,
                    'year': e.year,
                    'attachmentPoint': e.attachmentPoint,
                    'aggregateLimit': e.aggregateLimit,
                    'cedingPercentage': e.cedingPercentage,
                    'exhaustionPoint': e.exhaustionPoint
                }))}
                editable={{
                    onRowAdd: newData => saveNewData(newData),
                    //onRowUpdate: (newData, oldData) => updateData(newData, oldData),
                    onRowDelete: oldData => deleteData(oldData),
                }}
            />
        </div>
    )
}
