import { saveAs } from 'file-saver';

export function getCenterBbox(bbox) {
    return [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2]
}

export function exportMapPNG(map) {
    map.once('idle', () => {
        map.getCanvas().toBlob(function(blob) {
            saveAs(blob, 'map.png');
        })
    })
}

export function fly(map, storm) {
    map.flyTo({
        center: getCenterBbox(storm.bbox),
        zoom: 4.1,
        essential: true,
        bearing: 0,
        speed: 1, // make the flying slow
        //easing: (t) => t,
    })
}
