import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import TreeView from '@mui/lab/TreeView';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import {Box, Divider} from "@mui/material";
import { useSpring, animated } from 'react-spring';
import {useContext} from "react";
import {DataContext} from "../contexts/DataContext";
import {capitalize1stLetter, padWithChars} from "../utils/formatFunctions";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

export default function StormsTreeView(props) {

    const {pastStormsSeason, pastStorms, latestStorms, allStorms} = props

    const { t } = useTranslation();

    const {
        updateSelectedStorm,
        updateStormsToShow
    } = useContext(DataContext);

    let year;

    const handleNodeSelect = (event) => {
        let stormsToShow = []
        if (event.target.innerHTML[0] === 'S') {
            const storm_id = event.target.innerHTML.split(' ')[0]
            year = Number(storm_id.slice(4, 8))
            if (year >= yearKACmpresdata) {
                updateSelectedStorm(latestStorms.filter((i) => i.id === storm_id)[0]);
            } else {
                updateSelectedStorm(pastStorms.filter((i) => i.id === storm_id)[0]);
            }
        } else {
            year = Number(event.target.innerHTML.slice(5,9))
            console.log('year',year)
            if (year >= yearKACmpresdata) {
                stormsToShow = latestStorms
                    .filter((i) => i.id.slice(4) === year.toString())
                    .map((e) => 'mpres_data/postevent/taos_swio30s_ofcl_windwater_shp/taos_swio30s_ofcl_windwater_shp_' + e.id + '.geojson')
                updateStormsToShow(stormsToShow)
            } else {
                stormsToShow = pastStorms
                    .filter((i) => i.id.slice(4) === year.toString())
                    .map((e) => 'jtwc_history/' + year + '/' + e.id + '_shp.geojson')
                updateStormsToShow(stormsToShow)
            }
        }
    }

    const yearKACmpresdata = 2022 // This is when mpres_data was made available from KAC. Before, it is located in historical data.  // Number(Object.keys(pastStormsSeason).reverse()[0])

    return (
        <Box sx={{ minWidth: 360, boxShadow: 20, marginLeft:2, maxWidth: 400}}>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto', backgroundColor: 'white' }}
                onNodeSelect={handleNodeSelect}
            >

{/*                <StyledTreeItem nodeId={0} label={`${Number(lastYearSeason)}/${lastYearSeason+1}`}>
                    {latestStorms.map((storm, id) => {
                        const label = storm.id + ' ' + padWithChars(capitalize1stLetter(storm.storm_name), 10, ' ') + ' ' + storm.date
                        return <StyledTreeItem nodeId={id + 1} label={label} sx={{whiteSpace: 'pre', textAlign: 'justify'}}/> // {storm.storm_name}
                    })}
                </StyledTreeItem>

                <Divider />*/}

                {/*TODO: repeat the same code with latestStorms*/}
                {Object.keys(pastStormsSeason).reverse().map((year, index) => {
                    return <Tooltip placement={'top'} title={`${t('buttons.storms.season.helper')} ${Number(year)-1}/${year}`}>
                        <div>
                            <StyledTreeItem nodeId={index + 1} label={`${Number(year)-1}/${year}`}>
                                {pastStormsSeason[year]
                                    .sort((a, b) => a.date < b.date ? 1 : -1)
                                    .map((storm, id) => {
                                        const label = storm.id + ' ' + padWithChars(capitalize1stLetter(storm.storm_name), 10, ' ') + ' ' + storm.date
                                        return <div className={'tree-item'}>
                                                <StyledTreeItem nodeId={(index + 1) * 100 + id} label={label} sx={{whiteSpace: 'pre', textAlign: 'justify'}}/> {/*// label={`${storm.id} ${storm.storm_name}${' '.repeat(15 - storm.storm_name.length)} ${storm.date}`}*/}
                                        </div>
                                    })}
                            </StyledTreeItem>
                        </div>
                    </Tooltip>
                })}

            </TreeView>
        </Box>
    );
}
