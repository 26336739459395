import {Divider, Paper} from "@mui/material";
import StormTable from "./stormTable";
import React, {useContext, useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {Tooltip} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import {capitalize1stLetter, convertDMS, numberWithCommas} from "../utils/formatFunctions";
import {
    CAT_KNOTS,
    WIND_CATEGORIES,
    WIND_COLORS,
    WIND_TEXT,
    wind_categories,
    DAYS_AFTER_STORM,
    WIND_GRADIENT_COLORS,
    SURGE_GRADIENT_COLORS,
    KNTS2KMH,
    POPULATION_GRADIENT_COLORS,
    VALUE_GRADIENT_COLORS,
    HEATMAP_GRADIENT_COLORS,
    TRAJECTORIES_GRADIENT_COLORS,
    EXCESS_RAIN_GRADIENT_COLORS, LANDSLIDE_GRADIENT_COLORS
} from "../constants/constants";
import {getFlagCountry, windCategory} from "../utils/miscFunctions";
import {DataContext} from "../contexts/DataContext";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {DataStore} from "@aws-amplify/datastore";
import {RiskParameter} from "../models";
import {payout} from "../utils/payoutFunctions";
import CloseButton from "./closeButton";
import Legend from "./legend";

const CustomIconButton = styled(IconButton)({
    padding: 0
})


export default function StormOverlay(props) {

    const {selectedStorm, selectedStormData, currentLosses, stormPositions, todayData, vmax, maxSurge, currentStorm, clearMapSelectedStorm} = props

    const { t } = useTranslation();

    const {
        attachmentPoint,
        aggregateLimit,
        cedingPercentage,
        exhaustionPoint
    } = useContext(DataContext);

    const AP = attachmentPoint
    const L = aggregateLimit
    const y = cedingPercentage
    const EP = exhaustionPoint

    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const readSimulation = authStatus === 'authenticated' && user.signInUserSession.accessToken.payload["cognito:groups"].some(e => ['simulation','payout','admin'].includes(e))
    const readPayout = authStatus === 'authenticated' && user.signInUserSession.accessToken.payload["cognito:groups"].some(e => ['payout','admin'].includes(e))

    const [open, setOpen] = useState(props.open)
    const [activeCoordinates, setActiveCoordinates] = useState([])
    const [activeSpeed, setActiveSpeed] = useState(null)
    const [indexMax, setIndexMax] = useState(windCategory(vmax)) // useState(selectedStorm.type === 'current' ? windCategory(todayData.VMAX): windCategory(vmax))
    const [index, setIndex] = useState(null)
    const [riskParameters, setRiskParameters] = useState(null)

    const handleClose = () => {
        clearMapSelectedStorm();
        setOpen(false);
    };

    const handleBackCurrentStorm = () => {
        updateSelectedStorm(current_storm);
    };

    const {
        selectedDTG,
        updateSelectedDTG,
        current_storm,
        updateSelectedStorm,
        pastPeakWind,
        pastPeakWater,
        excessRain,
        accRain3h,
        accRain1d,
        accRain3d,
        accRain7d,
        population,
        value,
        stochastic_heatmap,
        stochastic_trajectories
    } = useContext(DataContext);

    useEffect(() => {
        if (selectedStorm.type === 'current' && todayData && vmax) {
            console.log('about to change selectedDTG to', todayData.DTG)
        }
    }, [])

    useEffect(() => {
        if (readPayout) {
            fetchData() // REPLACE WITH
        }
    },[])

    useEffect(() => {
        if (selectedDTG && stormPositions.length > 0) {

            // get coordinates and speed at selection's time
            let selection = stormPositions.filter(x => x.DTG === selectedDTG)[0]
            let coordinates = selection.coordinates
            let speed = selection.VMAX

            setActiveCoordinates(coordinates)
            setActiveSpeed(speed)
            setIndex(windCategory(speed))
        }
    }, [selectedDTG])

    useEffect(() => {
        setOpen(true)
    }, [selectedStorm])

    async function fetchData() {
        const records = await DataStore.query(RiskParameter);
        setRiskParameters(records)
    }

    const test = 0

    // Calculating the number of days since the end of the storm
    const current_year = new Date().getFullYear()
    let payout_simulation_flag = true

    if (Number(selectedStorm.year) === current_year) {
        const today = new Date()
        const last_day_storm = new Date(stormPositions[stormPositions.length-1].DTG)
        const days_difference = (today.getTime() - last_day_storm.getTime()) / (1000 * 3600 * 24)

        // Payout and Simulation available when passed a certain number of days after the end of the storm
        payout_simulation_flag = days_difference >= DAYS_AFTER_STORM
    }

    return (
        <div className='overlay' style={{ top: '10vh', right: '20px', width: '20vw'}}>
            {open &&

            <Paper
                elevation={15}
                sx={{
                    backgroundColor: 'rgba(234,234,234,0.72)',
                }}
            >

                <div style={{overflow: 'scroll', maxHeight: '80vh'}}>

                    <CloseButton handleClose={handleClose} />

                    <div className='storm-overlay'>
                        {selectedStorm.type === 'current' && <span className={'active-cyclone'}>LIVE</span>}
                        {current_storm && selectedStorm.type !== 'current' &&
                        <Tooltip placement={'top'} title={t(`storm_overlay.buttonBackCurrentStorm.helper`)}>
                            <IconButton>
                                <SettingsBackupRestoreIcon fontSize={'large'} color={'primary'} onClick={handleBackCurrentStorm}/>
                            </IconButton>
                        </Tooltip>
                        }
                        {t('storm_overlay.title')} <strong className="storm-name">{capitalize1stLetter(selectedStorm.storm_name)}</strong>
                    </div>
                    {/*                    <IconButton aria-label="delete" className={'close-button'} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>*/}
                    <StormTable />
                    <div className={'storm-specs'}>
                        {/* UNCOMMENT FOR COLORED CYCLONE CATEGORY<div>{index && <span style={{color: WIND_COLORS[index]}}><strong>{WIND_CATEGORIES[index]}</strong></span> }</div>*/}
                        <Tooltip placement={'top'} title={t(`constants.wind_cats.${indexMax}.helper`)}>
                            <div>
                                <span
                                    className={'cyclone-type'}
                                    style={{
                                        backgroundColor: WIND_COLORS[windCategory(vmax)],
                                        color: ['B','D','IC','VC'].includes(wind_categories[windCategory(vmax)].short) ? 'white' : '',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {wind_categories[windCategory(vmax)].short}
                                </span>
                                {windCategory(vmax) && <span><strong>{t(`constants.wind_cats.${windCategory(vmax)}.title`)}</strong></span> }
                            </div>
                        </Tooltip>
                        <Tooltip placement={'top'} title={t(`storm_overlay.max_speed.helper`)}>
                            <div>{`${t(`storm_overlay.max_speed.title`)}: `+ (vmax * 1.852).toFixed(0)} km/h</div>
                        </Tooltip>
                        <Tooltip placement={'top'} title={t(`storm_overlay.max_surge.helper`)}>
                            <div>{maxSurge && `${t(`storm_overlay.max_surge.title`)}: ${maxSurge} m`}</div>
                        </Tooltip>
                        {selectedStorm.type === 'current'
                            && <div>{convertDMS(todayData.LAT, todayData.LON)}</div>}
                        {selectedDTG && <Divider />}
                        {selectedDTG
                            && <div>
                                <Tooltip placement={'top'} title={t(`constants.wind_cats.${index}.helper`)}>
                                    <div>
                                <span
                                    className={'cyclone-type'}
                                    style={{
                                        backgroundColor: WIND_COLORS[windCategory(activeSpeed)],
                                        color: ['B','D','IC','VC'].includes(wind_categories[windCategory(activeSpeed)].short) ? 'white' : '',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {wind_categories[windCategory(activeSpeed)].short}
                                </span>
                                        {index && <span><strong>{t(`constants.wind_cats.${index}.title`)}</strong></span> }
                                    </div>
                                </Tooltip>
                                {selectedDTG}
                                <div>speed: {(activeSpeed * 1.852).toFixed(0)} km/h</div>
                                <div>{convertDMS(activeCoordinates[1], activeCoordinates[0])}</div>
                            </div>
                        }
                    </div>
                    {
                        readSimulation && payout_simulation_flag && (AP != 0) && (L != 0) && (y != 0) && (EP != 0) && currentLosses && currentLosses.length > 0 &&
                        <div className={'storm-details'}>
                            <Divider />
                            <Tooltip placement={'top'} title={t('storm_overlay.simulation.helper')}>
                                <div>
                                    {t('storm_overlay.simulation.title')}
                                </div>
                            </Tooltip>
                            {selectedStorm && currentLosses.map((ctry, i) => {
                                return <div className={'countries-exposed'} key={ctry + i + 10}>
                                    <strong>{ctry.adm0_name}</strong>
                                    <div className={'country-exposed'}>
                                        {numberWithCommas(payout(L,y,EP,AP, ctry.loss), '$')}
                                    </div>
                                </div>
                            })}
                        </div>
                    }
                    {
                        readPayout && payout_simulation_flag && riskParameters && currentLosses && currentLosses.length > 0 &&
                        <div className={'storm-details'}>
                            <Divider />
                            <Tooltip placement={'top'} title={t('storm_overlay.payout.helper')}>
                                <div>
                                    {t('storm_overlay.payout.title')}
                                </div>
                            </Tooltip>
                            {selectedStorm && currentLosses.map((ctry, i) => {
                                //TODO: add filter on the user's country (if payout user group)
                                const r = riskParameters.filter(e => e.country === ctry.adm0_name && e.year === Number(selectedStorm.id.slice(4)) - 1)[0]
                                if (r) {
                                    const L = r.aggregateLimit
                                    const EP = r.exhaustionPoint
                                    const AP = r.attachmentPoint
                                    const y = r.cedingPercentage
                                    return <div className={'countries-exposed'} key={ctry + i + 10}>
                                        <strong>{ctry.adm0_name}</strong>
                                        <div className={'country-exposed'}>
                                            {numberWithCommas(payout(L,y,EP,AP, ctry.loss), '$')}
                                        </div>
                                    </div>
                                }
                            })}
                        </div>
                    }
                    {
                        currentLosses.length > 0 &&
                        <div className={'storm-details'}>
                            <div className={'countries-exposed'}>
                                {/*UNCOMMENT TO SEE THE BREAKDOWN OF AFFECTED POPULATION PER WIND CATEGORY*/}
    {/*                            {selectedStorm && currentLosses.map((ctry, i) => {
                                    return <div className={'country-exposed'} key={ctry + i}>
                                        <img src={getFlagCountry(ctry.adm0_name)} width="30" height="20"/>
                                        <strong>{ctry.adm0_name}</strong>
                                        <strong>{numberWithCommas(ctry.population)}</strong>
                                        <div className={'country-category'}>
                                            <Divider />
                                            {Object.keys(ctry.wind_cat).map((key, index) => (
                                                <div
                                                    key={index}
                                                    style={{color: index >= 1 ? WIND_COLORS[index - 1] : 'black'}}
                                                >
                                                    {WIND_TEXT[key]}: {numberWithCommas(ctry.wind_cat[key])}  cat {key}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                })}*/}
                                {selectedStorm && currentLosses && WIND_TEXT.map((key, index) => {
                                        return <div
                                            key={index}
                                            style={{color: index >= 1 ? WIND_COLORS[index - 1] : 'black'}}
                                        >
                                            {WIND_TEXT[key]}
                                        </div>
                                    }
                                )
                                }
                            </div>
                        </div>
                    }
                    {
                        pastPeakWind &&
                            <div>
                                <Divider />
                                <Legend
                                    legendTitle={`${t('buttons.exploration.menu.wind.title')} (km/h)`}
                                    colorStops={WIND_GRADIENT_COLORS}
                                    conversionFactor={KNTS2KMH}
                                    id={'wind'}
                                />
                            </div>
                    }
                    {
                        pastPeakWater &&
                            <div>
                                <Divider />
                                <Legend
                                    legendTitle={`${t('buttons.exploration.menu.surge.title')} (m)`}
                                    colorStops={SURGE_GRADIENT_COLORS}
                                    id={'surge'}
                                />
                            </div>
                    }
                    {
                        (excessRain || accRain3h || accRain1d || accRain3d || accRain7d) &&
                        <div>
                            <Divider />
                            <Legend
                                legendTitle={`${t('buttons.exploration.menu.excessRain.title')} (mm)`}
                                colorStops={EXCESS_RAIN_GRADIENT_COLORS}
                                id={'excessRain'}
                            />
                        </div>
                    }
                    {
                        population &&
                            <div>
                                <Divider />
                                <Legend
                                    legendTitle={`${t('buttons.exploration.menu.population.title')} (M)`}
                                    colorStops={POPULATION_GRADIENT_COLORS}
                                    id={'population'}
                                    conversionFactor={0.000001}
                                    round={false}
                                />
                            </div>
                    }
                    {
                        value &&
                        <div>
                            <Divider />
                            <Legend
                                legendTitle={`${t('buttons.exploration.menu.economic.title')} ($M)`}
                                colorStops={VALUE_GRADIENT_COLORS}
                                id={'value'}
                                conversionFactor={0.000001}
                                round={false}
                            />
                        </div>
                    }
                    {
                        stochastic_heatmap &&
                        <div>
                            <Divider />
                            <Legend
                                legendTitle={`${t('buttons.exploration.menu.heatmap.title')}`}
                                colorStops={HEATMAP_GRADIENT_COLORS}
                                id={'heatmap'}
                                round={false}
                            />
                        </div>
                    }
                    {
                        stochastic_trajectories &&
                        <div>
                            <Divider />
                            <Legend
                                legendTitle={`${t('buttons.exploration.menu.trajectories.title')} (km/h)`}
                                colorStops={TRAJECTORIES_GRADIENT_COLORS}
                                conversionFactor={KNTS2KMH}
                                id={'trajectories'}
                            />
                        </div>
                    }
                </div>
            </Paper>
            }
        </div>
    )
}

