import React, {useContext, useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import MuiInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import RiskParametersSlider from "./riskParametersSlider";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import RiskParametersInput from "./riskParametersInput";
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import {DataStore} from "@aws-amplify/datastore";
import {RiskParameter} from "../models";
import EditableTable from "./editableTable";
import CloseButton from "./closeButton";

const Input = styled(MuiInput)`
  width: 42px;
`;

const seasonYear = new Date().getFullYear()


async function saveNewRecord(newRecord) {
    await DataStore.save(
        newRecord
    );
}

async function updateRecord(id, newRecord) {
    const original = await DataStore.query(RiskParameter, id);
    await DataStore.save(
        RiskParameter.copyOf(original, updated => {
            updated.attachmentPoint = newRecord.attachmentPoint
            updated.aggregateLimit = newRecord.aggregateLimit
            updated.cedingPercentage = newRecord.cedingPercentage
            updated.exhaustionPoint = newRecord.exhaustionPoint
        })
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.9
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80vw',
        height: '60vh',
        overflow:'scroll'
    },
}));


export default function SelectSettingsAdmin() {

    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const [country, setCountry] = useState('');
    const [year, setYear] = useState(seasonYear);
    const [attachmentPoint, setAttachmentPoint] = useState(0);
    const [aggregateLimit, setAggregateLimit] = useState(0);
    const [cedingPercentage, setCedingPercentage] = useState(0);
    const [exhaustionPoint, setExhaustionPoint] = useState(0);

    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        if (country !== '') {

            const newRecord = new RiskParameter({
                "country": country,
                "year": year, // new Date().getFullYear(),
                "attachmentPoint": attachmentPoint,
                "aggregateLimit": aggregateLimit,
                "cedingPercentage": cedingPercentage,
                "exhaustionPoint": exhaustionPoint
            })

            const riskParameters = await DataStore.query(RiskParameter, r => r.country("eq", country).year("eq", year))

            if (riskParameters.length > 0) {
                // if the country is already in the database, take the id and replace it with the new value
                const id = riskParameters[0].id
                updateRecord(id, newRecord)
            } else {
                saveNewRecord(newRecord)
            }

        }
    }

    const handleQuery = async (e) => {
        const models = await DataStore.query(RiskParameter);
        console.log(models);
    }

    const handleClear = async (e) => {
        await DataStore.clear();
    }

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleChangeYear = (event) => {
        setYear(event.target.value);
    };


    return (
        <div className={'settings'}>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <CloseButton handleClose={handleClose}/>
                        <EditableTable />
{/*                        <Typography id="input-slider" gutterBottom>
                            Risk Parameters
                        </Typography>*/}

{/*                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={country}
                                label="Country"
                                onChange={handleChangeCountry}
                            >
                                <MenuItem value={'Madagascar'}>Madagascar</MenuItem>
                                <MenuItem value={'Comoros'}>Comoros</MenuItem>
                                <MenuItem value={'La Réunion'}>La Réunion</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={year}
                                label="Year"
                                onChange={handleChangeYear}
                            >
                                <MenuItem value={2022}>2022</MenuItem>
                                <MenuItem value={2021}>2021</MenuItem>
                                <MenuItem value={2020}>2020</MenuItem>
                            </Select>
                        </FormControl>

                        <RiskParametersInput name={t('settings.payout.attachment_point')} initialValue={attachmentPoint} updateValue={setAttachmentPoint}/>
                        <RiskParametersInput name={t('settings.payout.coverage_limit')} initialValue={aggregateLimit} updateValue={setAggregateLimit}/>
                        <RiskParametersSlider name={t('settings.payout.ceding_percentage')} initialValue={cedingPercentage} updateValue={setCedingPercentage}/>
                        <RiskParametersInput name={t('settings.payout.exhaustion_point')} initialValue={exhaustionPoint} updateValue={setExhaustionPoint}/>

                        <Button onClick={handleSubmit} variant="contained">Submit</Button>
                        <Button onClick={handleQuery} variant="contained">Query</Button>
                        <Button onClick={handleClear} variant="contained">Clear</Button>*/}

                    </div>
                </Fade>
            </Modal>

        </div>
    );
}



