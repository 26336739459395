import React, {useContext, useEffect, useState} from 'react';

import 'devextreme/dist/css/dx.light.css';

import PieChart, {
    Series,
    Legend,
    Label,
    Connector, Export, Size,
} from 'devextreme-react/pie-chart';

import CenterTemplate from './centerTemplate.js';
import {DataContext} from "../contexts/DataContext";
import {WIND_TEXT} from "../constants/constants";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export default function PieChartCountry(props) {

    const {currentLosses} = props;

    const [data, setData] = useState([]);

    const {
        wind_colors
    } = useContext(DataContext);

    //const countriesLoss = currentLosses.map(x => ({adm0_name: x.adm0_name, wind_cat: Object.entries(x.wind_cat).map(([key, value]) => ({'adm0_name': x.adm0_name, 'cat': key, 'pop': value}))}))
    //const countriesLoss = currentLosses.map(x => ({adm0_name: x.adm0_name, wind_cat: Object.entries(x.wind_cat).slice(2).map(([key, value]) => ({'adm0_name': x.adm0_name, 'cat': key, 'pop': value}))}))
    const countriesLoss = currentLosses.map(x => ({adm0_name: x.adm0_name, wind_cat: Object.entries(x.wind_cat).filter(y => y[0] >= 1).map(([key, value]) => ({'adm0_name': x.adm0_name, 'cat': String(Number(key)+1), 'pop': value}))}))

    for (const country of countriesLoss) {
/*        country.wind_cat.forEach((e,i,a) => {
            if (e.cat != i + 1) {
                a.splice(i, 0, {adm0_name: e.adm0_name, cat: (i + 1).toString(), pop: 0})
            }
        })*/
        if (country.wind_cat.length > 0) {
            data.push(country)
        }
    }

    const countries = Array.from(new Set(data.map((item) => item.adm0_name)));

    const customizeLabel = (e) => {
        return `${e.argumentText}\n${e.valueText}`;
    }

    const pies = countries.map((country) => (
        <PieChart
            id="pie-chart"
            key={country}
            dataSource={data.filter((i) => i.adm0_name === country)[0].wind_cat}
            resolveLabelOverlapping="shift"
            sizeGroup="piesGroup"
            innerRadius={0.65}
            centerRender={CenterTemplate}
            type="doughnut"
            palette={wind_colors}
        >
            <Series
                argumentField="cat"
                valueField="pop"
            >
                <Label visible={true}
                       position="columns"
                       customizeText={customizeText}
                       backgroundColor="none">
                    <Connector visible={true}></Connector>
                </Label>
            </Series>
            <Size width={400} />
            <Legend visible={false}></Legend>
            <Export enabled={true} />
        </PieChart>
    ));

    return (
        <div>
            <div className="pies-container">
                {pies}
            </div>
        </div>
    );
}


function customizeText({ argument, value }) {
    return `${WIND_TEXT[argument]}: ${numberWithCommas(value)}`;
}
