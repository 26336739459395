import React, {useRef, useEffect, useState, useContext} from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import config from "../config";
import {DataContext} from "../contexts/DataContext";
import {Divider} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SelectedStorm from "./selectedStorm";
import PopUpReport from './reportPopUp';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TceTitle } from './img/tce-title.svg';
import { ReactComponent as ArcLogo } from './img/arc-logo.svg';
import Settings from "./settings";
import SelectedExploration from "./selectedExploration";
import StormOverlay from "./stormOverlay";
import AdmOverlay from "./admOverlay";
import {formatDate, TODAY_DATE, PREV_DTG, NEXT_DTG} from '../utils/dateFunctions'
import {numberWithCommas} from '../utils/formatFunctions';
import {fly} from '../utils/mapFunctions';
import {
    showHideStormsThatHit,
    hideAllStormsThatHit,
    drawStorm,
    drawAllStormsThatHit,
    addSourceStorm,
    updateSourceStorm,
    showHideStorm, showHideExcessRain, addCustomGeoJSONLayer
} from "../utils/mapLayoutFunctions";
import {average} from "../utils/mathFunctions";
import {windCategory, hexToRgb} from "../utils/miscFunctions";
import {
    URL_ROOT,
    WIND_COLORS,
    ICONS,
    MIN_ZOOM,
    MAX_ZOOM,
    POPULATION_GRADIENT_COLORS,
    VALUE_GRADIENT_COLORS,
    HEATMAP_GRADIENT_COLORS,
    TRAJECTORIES_GRADIENT_COLORS,
    EXCESS_RAIN_GRADIENT_COLORS
} from '../constants/constants';
import Languages from "./languages";
import {getPathGFSs, getPathPPMs, getUrlGeoJSON, getUrlGeoJSONs} from "../utils/nasaWeatherFunctions";
import TimeAnimation from "./timeAnimation";



export default function MapContainer() {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(55.9);
    const [lat, setLat] = useState(-15.5);
    const [zoom, setZoom] = useState(3.4);
    const [pitch, setPitch] = useState(0);
    const [stormName, setStormName] = useState('');
    const [stormId, setStormId] = useState('');
    const [stormsData, setStormsData] = useState({});
    const [selectedStormData, setSelectedStormData] = useState([]);
    const [todayData, setTodayData] = useState([]);
    const [currentLosses, setCurrentLosses] = useState([]);
    const [vmax, setVmax] = useState(null);
    const [maxSurge, setMaxSurge] = useState(null);
    const [stormsThatHit, setStormsThatHit] = useState([])
    const [allStorms, setAllStorms] = useState([])
    const [stormsCounter, setStormsCounter] = useState(null)
    const [admName, setAdmName] = useState(null)
    const [admStats, setAdmStats] = useState(null)
    const [admPopulation, setAdmPopulation] = useState('')
    const [admValue, setAdmValue] = useState('')
    const [admAvgStorm, setAdmAvgStorm] = useState('')
    const [admBiggestLoss, setAdmBiggestLoss] = useState('')
    const [stormPositions, setStormPositions] = useState([]);
    const [openAlert, setOpenAlert] = useState(true);

    const { t } = useTranslation();

    //TODO: check what properties can be removed from DataContext
    // /!\ BEWARE of the usage in all the file, e.g. current_storm property (which might be redundant btw, is used to go back to the current storm when displaying the current storm
    // - LOOK FOR redundant and/or obsolete properties
    const {
        storm,
        currentStorm,
        current_storm,
        selectedStorm,
        updateSelectedStorm,
        adm0,
        adm1,
        adm2,
        population,
        value,
        stochastic_heatmap,
        stochastic_trajectories,
        pastPeakWind,
        pastPeakWater,
        pastRainTotal,
        accRain3h,
        accRain1d,
        fcstRain1d,
        fcstRain5d,
        accRain1dVideo,
        fcstRain1dVideo,
        storm_position_trajectory,
        storm_position_points,
        storm_position_labels,
        updateStormPositionPoints,
        updateStormPositionLabels,
        updateCurrentStorm,
        openStormOverlay,
        updateOpenStormOverlay,
        selectedDTG,
        updateSelectedDTG,
        stormsToShow
    } = useContext(DataContext);

    //// function to clean the current map from storms
    const clearMap = () => {
        hideAllStormsThatHit(map.current, allStorms)
    }

    //// function to clean the current map from the selectedStorm
    const clearMapSelectedStorm = () => {
        showHideStorm(map.current, selectedStorm, layers, false)
    }

    //// functions  to fetch data
    // get all the storms that hit
    const getStorms = (url, stormsThatHit=false) => {
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const stormsCounter = data.counter_total
                setStormsCounter(stormsCounter)
                if (stormsThatHit) {
                    setStormsThatHit(data.records)
                } else {
                    setAllStorms(data.records)
                }
            })
    }

    // get storm data
    //TODO: REMOVE all the unnecessary variables (see below function: it's the contraction of all of them)
    const getStormData = (selectedStorm) => {
        const { shp, id, storm_name } = selectedStorm;

        setStormsData({})
        setSelectedStormData([])
        fetch(shp)
            .then(response => response.json())
            .then(data => {

                let setStorms

                // check if data.features contains properties.ATCFID (historical data does not contain it)
                if (data.features[0].properties.ATCFID) {
                    setStorms = new Set(data.features.map(x => ({
                        id: x.properties.ATCFID,
                        storm_name: x.properties.NAME
                    })));
                } else { // historical data
                    setStorms = new Set([{
                        id: id,
                        storm_name: storm_name
                    }])
                }

                const objStorms = {}
                setStorms.forEach(storm => {
                    const stormDataFilter = data.features.filter(x => x.properties.NAME === storm.storm_name)
                    const todayDataFilter = stormDataFilter.filter(x => x.properties.DTG <= formatDate(TODAY_DATE)).slice(-1)[0].properties
                    const stormPositionsFilter = stormDataFilter.map(x => ({DTG: x.properties.DTG, coordinates: x.geometry.coordinates, VMAX: x.properties.VMAX}))
                    const vmaxFilter = Math.max(...stormDataFilter.map(x => x.properties.VMAX))
                    let key = storm.id
                    objStorms[key] = {data: stormDataFilter, todayData: todayDataFilter, stormPositions: stormPositionsFilter, vmax: vmaxFilter}
                })
                setStormsData(objStorms)
                setSelectedStormData(objStorms[selectedStorm.id])
            })
    }

    // get storm id and name
    const getLatestStormID = (url) => {
        setStormId('')
        setStormName('')
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setStormId(data.features[0].properties.ATCFID)
                setStormName(data.features[0].properties.NAME)
            })
    }

    // get current data
    const getCurrentData = (url) => {
        setTodayData([])
        fetch(url)
            .then(response => response.json())
            .then((data) => {
                setTodayData(data.features.filter(x => x.properties.DTG <= formatDate(TODAY_DATE) && x.properties.BASIN ? x.properties.BASIN === 'SH' : true).slice(-1)[0].properties)
            })
    }

    const getStormPositions = (url) => {
        fetch(url)
            .then(response => response.json())
            .then((resp) => resp.features.filter(x => x.geometry.type === 'Point' && x.properties.BASIN === 'SH' && x.properties.LON <= 113))
            .then((resp) => {
                setStormPositions(resp.map(x => ({DTG: x.properties.DTG, coordinates: x.geometry.coordinates, VMAX: x.properties.VMAX})))
            })
    }

    // get vmax
    const getVmax = (url) => {
        fetch(url)
            .then(response => response.json())
            .then((data) => {
                if (selectedStorm.type === 'current') {
                    setVmax(Math.max(...data.features.filter(x => x.properties.BASIN === 'SH').map(x => x.properties.VMAX)))
                } else {
                    setVmax(Math.max(...data.features.map(x => x.properties.VMAX)))
                }
            })
    }

    // get max surgegit
    const getMaxSurge = (url) => {
        fetch(url)
            .then(response => response.json())
            .then((data) => {
                // TODO: apply conditional treatment if selectedStorm.type === 'current', filtering out basin 'SH' (cf. getVmax(url) )
                const surge = Math.max(...data.features.filter(x => x.properties.field === 'past_peak_water').map(x => x.properties.val1))
                setMaxSurge(Math.round((surge + Number.EPSILON) * 100) / 100)
            })
    }

    // get current losses
    const getCurrentLosses = (url) => {
        setCurrentLosses([])
        fetch(url)
            .then(response => response.json())
            .then(data => data.records.map((x => ({adm0_name: x.adm0_name, loss: x.loss, population: x.population, wind_cat: x.wind_cat, adm1: x.adm1}))))
            .then((data) => {
                setCurrentLosses(data)
            })
    }

    let hoveredAdmId = null;

    //// layers => layerId: isVisible
    const layers = {
        'pastRainTotal': pastRainTotal,
        'pastPeakWind': pastPeakWind,
        'pastPeakWater': pastPeakWater,
        'precip_3hr': accRain3h,
        'precip_3hr_1d': accRain1d,
        'fcstRain1d': fcstRain1d,
        'fcstRain5d': fcstRain5d,
        'accRain1dVideo': accRain1dVideo,
        'fcstRain1dVideo': fcstRain1dVideo,
        'storm trajectory': storm_position_trajectory,
        'storm points': storm_position_points,
        'storm info': storm_position_labels,
    }

    const layersPast = {
        'storm-past-wind-layer': true,
        'storm-fcst-wind-layer': false,
        'storm-fcst-trajectory': false,
    }

    /// *** This might have to be removed
    const layersAdm = {
        'adm0': adm0,
        'adm1': adm1,
        'adm2': adm2
    }

    const layersStatic = {
        'population': population,
        'value': value,
        'stochastic_heatmap': stochastic_heatmap,
        'stochastic_trajectories': stochastic_trajectories,
    }

    function pulsatingDot(size, color) {
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // Call once before every frame where the icon will be used.
            render: function () {
                const duration = 1000;
                const t = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                //const outerRadius = 2 * radius + radius * Math.sin((size / 2) * 0.7 * t / (2 * 2 * 3.14));
                const context = this.context;

                // Draw the outer circle.
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                if (color[0] === '#') {
                    let rgb = hexToRgb(color)
                    context.fillStyle = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${1 - t})`;
                } else {
                    context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
                }
                context.fill();

                // Draw the inner circle.
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = color;
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // Update this image's data with data from the canvas.
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                map.current.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                //return true;

                // clean up on unmount
                return () => map.current.remove();

            }
        };
        return pulsingDot
    }

    //TODO: is the next line necessary?
    let selectedPulsatingDot = pulsatingDot(100, 'blue')


    //// initiating empty geojson for pulsing dot
    let static_dot_geojson = {
        "type": "FeatureCollection",
        "features": []
    };

    // add marker with coordinates for pulsating dot
    let marker = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: []
        }
    };


    ////// Side effects

    ///// 1x to fetch the storms that hit
    useEffect(() => {
        getStorms(URL_ROOT + 'historyAdm.json', true)
        getStorms(URL_ROOT + 'storms.json')
    }, [])

    //// 1x initialization of the map
    useEffect(() => {
        if (map.current) {
            console.log('map already initialized')
            return; // initialize map only once
        }
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/bertranddelvaux/cl14sbxgz008q14pcjgdtm1ww', // Minimo min version
            center: [ lng, lat ],
            zoom: zoom,
            pitch: pitch,
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            renderWorldCopies: false,
            attributionControl: false, // Check, but legally, it should be set to 'true'
            accessToken: config.mapboxToken
        });
        console.log('Init useEffect')

        // Add zoom and rotation controls to the map.
        map.current.addControl(new mapboxgl.ScaleControl(), 'bottom-left');

        // Add zoom and rotation controls to the map.
        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

        //TODO: this can be removed
        // Adding cyclone icons on the map
        ICONS.forEach(icon => {
            map.current.loadImage(icon.url, function(error, image) {
                map.current.addImage(icon.id, image)
            })
        })

    }, [])

    ///// 1x static layers
    useEffect(() => {
        //TODO: name each effect more appropriately in the console
        console.log('Other useEffect')
        if (!map.current) {
            console.log('map not yet initialized')
            return;
        }

        //const frameCount = 15;
        const frameCountPast = 15
        let currentImagePast = 0;

        const pathPPMs = getPathPPMs(frameCountPast)

        const frameCountFcst = 40; //TODO: unused
        let currentImageFcst = 0;

        const pathGFSs = getPathGFSs()

        function getPathPPM() {
            return pathPPMs[currentImagePast]
        }

        function getPathGFS() {
            return pathGFSs[currentImageFcst]
        }

        map.current.on('load', () => {

            console.log('map on load')

            ///////////////////////////////////////////
            //// Add animation past
            addCustomGeoJSONLayer(map.current, 'accRain1dVideo', getPathPPM(), 'precip', EXCESS_RAIN_GRADIENT_COLORS, accRain1dVideo)


/*            setInterval(() => {
                currentImagePast = (currentImagePast + 1) % (frameCountPast * 8);
                map.current.getSource(`${datasetName}-source`).setData(getPathPPM());
            }, 100);*/

            //// Add animation fcst
            addCustomGeoJSONLayer(map.current, 'fcstRain1dVideo', getPathGFS(), 'val', EXCESS_RAIN_GRADIENT_COLORS, fcstRain1dVideo)

            //////////////////////////////////////////




            //// Add Custom Layer 1d 3h
            let datasetPPM = 'precip_3hr'
            let field = 'precip'
            let startDate = formatDate(TODAY_DATE, '-')
            let endDate = formatDate(TODAY_DATE, '-')
            const urlGeoJSONs3h = getUrlGeoJSONs(datasetPPM, startDate, endDate)
            addCustomGeoJSONLayer(map.current, datasetPPM, urlGeoJSONs3h[0], field, EXCESS_RAIN_GRADIENT_COLORS, accRain3h)

            //// Add Custom Layer 1d 3h
/*            datasetPPM = 'precip_3hr_1d'
            field = 'precip'
            startDate = formatDate(TODAY_DATE, '-')
            endDate = formatDate(TODAY_DATE, '-')
            const urlGeoJSONs = getUrlGeoJSONs(datasetPPM, startDate, endDate)
            addCustomGeoJSONLayer(map.current, datasetPPM, urlGeoJSONs[0], field, EXCESS_RAIN_GRADIENT_COLORS, accRain1d)*/

            //// Add Custom Layer 3d
            datasetPPM = 'precip_3d'
            field = 'precip'
            let urlGeoJSON = getUrlGeoJSON(datasetPPM)
            addCustomGeoJSONLayer(map.current, datasetPPM, urlGeoJSON, field, EXCESS_RAIN_GRADIENT_COLORS)

            //// Add Custom Layer 7d
            datasetPPM = 'precip_7d'
            field = 'precip'
            urlGeoJSON = getUrlGeoJSON(datasetPPM)
            addCustomGeoJSONLayer(map.current, datasetPPM, urlGeoJSON, field, EXCESS_RAIN_GRADIENT_COLORS)


            //// Add Custom Layer accumulation 1d 3h
            addCustomGeoJSONLayer(map.current, 'precip_3hr_1d', URL_ROOT + 'rain/gpm_realtime/gpm_1d.geojson', 'precip', EXCESS_RAIN_GRADIENT_COLORS, accRain1d)

            //// Add Custom Layer forecast 1d
            addCustomGeoJSONLayer(map.current, 'fcstRain1d', URL_ROOT + 'rain/gfs_realtime/gfs_00d_01d.geojson', 'val', EXCESS_RAIN_GRADIENT_COLORS, fcstRain1d)

            //// Add Custom Layer forecast 5d
            addCustomGeoJSONLayer(map.current, 'fcstRain5d', URL_ROOT + 'rain/gfs_realtime/gfs_00d_05d.geojson', 'val', EXCESS_RAIN_GRADIENT_COLORS, fcstRain5d)



            //// Adm layers

            /// adm2 hovering/info
            map.current.addSource('adm2-source',{
                'type': 'geojson',
                'data': URL_ROOT + 'adm2_popvalue.json',
                'generateId': true
            }); // adm2 source

            map.current.addLayer({
                'id': 'adm2-fills',
                'type': 'fill',
                'source': 'adm2-source',
                'layout': {},
                'paint': {
                    'fill-color': '#4a69b6',
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.7,
                        0
                    ]
                }
            }); // adm2 layer

            /// adm1 hovering/info
            map.current.addSource('adm1-source',{
                'type': 'geojson',
                'data': URL_ROOT + 'adm1_popvalue.json',
                'generateId': true
            }); // adm1 source

            map.current.addLayer({
                'id': 'adm1-fills',
                'type': 'fill',
                'source': 'adm1-source',
                'layout': {},
                'paint': {
                    'fill-color': '#8e4ab6',
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.7,
                        0
                    ]
                }
            }); // adm1 layer

            /// adm0 hovering/info
            map.current.addSource('adm0-source',{
                'type': 'geojson',
                'data': URL_ROOT + 'adm0_popvalue.json',
                'generateId': true
            }); // adm0 source

            map.current.addLayer({
                'id': 'adm0-fills',
                'type': 'fill',
                'source': 'adm0-source',
                'layout': {},
                'paint': {
                    'fill-color': '#b64a9d',
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.7,
                        0
                    ]
                }
            }); // adm0 layer


            //TODO: offset this
            //// *** Population and Value
            map.current.addSource('popexp-source', {
                'type': 'geojson',
                'data': URL_ROOT + 'adm2_popvalue.json' //'adm2-popexp.json'
            }); // population/value source

            map.current.addLayer({
                    'id': 'population',
                    'type': 'fill',
                    'source': 'popexp-source',
                    'layout': {
                        'visibility': 'none',
                    },
                    'paint': {
                        'fill-color': [
                            'interpolate',
                            ['linear'],
                            ['get', 'POPULATION'],
                            // Solution A
                            POPULATION_GRADIENT_COLORS[0].value,
                            POPULATION_GRADIENT_COLORS[0].color,
                            POPULATION_GRADIENT_COLORS[1].value,
                            POPULATION_GRADIENT_COLORS[1].color,
                            POPULATION_GRADIENT_COLORS[2].value,
                            POPULATION_GRADIENT_COLORS[2].color,
                        ],
                        'fill-opacity': [
                            'interpolate',
                            ['linear'],
                            ['get', 'POPULATION'],
                            POPULATION_GRADIENT_COLORS[0].value,
                            0.5,
                            POPULATION_GRADIENT_COLORS[1].value,
                            0.7,
                            POPULATION_GRADIENT_COLORS[2].value,
                            0.9
                        ],
                    },
                })

            map.current.addLayer({
                    'id': 'value',
                    'type': 'fill',
                    'source': 'popexp-source',
                    'layout': {
                        'visibility': 'none',
                    },
                    'paint': {
                        'fill-color': [
                            'interpolate',
                            ['linear'],
                            ['get', 'VALUE'],
                            // Solution A
                            VALUE_GRADIENT_COLORS[0].value,
                            VALUE_GRADIENT_COLORS[0].color,
                            VALUE_GRADIENT_COLORS[1].value,
                            VALUE_GRADIENT_COLORS[1].color,
                            VALUE_GRADIENT_COLORS[2].value,
                            VALUE_GRADIENT_COLORS[2].color,
                        ],
                        'fill-opacity': [
                            'interpolate',
                            ['linear'],
                            ['get', 'VALUE'],
                            VALUE_GRADIENT_COLORS[0].value,
                            0.5,
                            VALUE_GRADIENT_COLORS[1].value,
                            0.7,
                            VALUE_GRADIENT_COLORS[2].value,
                            0.9
                        ],
                    },
                }) // value layer


            //// Stochastic
            map.current.addSource('stochastic_heatmap_source', {
                'type': 'geojson',
                'data': URL_ROOT + 'stochastic.json'
            }); // stochastic heatmap source

            /// Stochastic heatmap
            map.current.addLayer({
                    'id': 'stochastic_heatmap',
                    'type': 'heatmap',
                    'source': 'stochastic_heatmap_source',
                    'maxzoom': 9,
                    'layout': {
                      'visibility': 'none',
                    },
                    'paint': {
                        // Increase the heatmap weight based on frequency and property magnitude
                        'heatmap-weight': [
                            'interpolate',
                            ['linear'],
                            ['get', 'VMAX'],
                            25,
                            0,
                            200,
                            1
                        ],
                        // Increase the heatmap color weight by zoom level
                        // heatmap-intensity is a multiplier on top of heatmap-weight
                        'heatmap-intensity': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            0,
                            1,
                            9,
                            3
                        ],
                        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                        // Begin color ramp at 0-stop with a 0-transparancy color
                        // to create a blur-like effect.
                        'heatmap-color': [
                            'interpolate',
                            ['linear'],
                            ['heatmap-density'],
                            HEATMAP_GRADIENT_COLORS[0].value,
                            HEATMAP_GRADIENT_COLORS[0].color,
                            HEATMAP_GRADIENT_COLORS[1].value,
                            HEATMAP_GRADIENT_COLORS[1].color,
                            HEATMAP_GRADIENT_COLORS[2].value,
                            HEATMAP_GRADIENT_COLORS[2].color,
                            HEATMAP_GRADIENT_COLORS[3].value,
                            HEATMAP_GRADIENT_COLORS[3].color,
                            HEATMAP_GRADIENT_COLORS[4].value,
                            HEATMAP_GRADIENT_COLORS[4].color,
                            HEATMAP_GRADIENT_COLORS[5].value,
                            HEATMAP_GRADIENT_COLORS[5].color,
                        ],
                        // Adjust the heatmap radius by zoom level
                        'heatmap-radius': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            0,
                            2,
                            9,
                            5
                        ],
                        // Transition from heatmap to circle layer by zoom level
                        'heatmap-opacity': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            7,
                            0.9,
                            9,
                            0
                        ]
                    }
                }); // stochastic heatmap layer

            //// Stochastic trajectories
            map.current.addSource('stochastic_trajectories_source', {
                'type': 'geojson',
                'data': URL_ROOT + 'stochastic_trajectories.json',
            }); // stochastic trajectories source

            //// Stochastic storms trajectories layer
            map.current.addLayer({
                'id': 'stochastic_trajectories',
                'type': 'line',
                'source': 'stochastic_trajectories_source',
                'layout': {
                    'visibility': 'none',
                },
                'paint': {
                    //'line-color': '#f67908',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['get', 'VMAX'],
                        TRAJECTORIES_GRADIENT_COLORS[0].value,
                        1,
                        TRAJECTORIES_GRADIENT_COLORS[1].value,
                        3,
                        TRAJECTORIES_GRADIENT_COLORS[2].value,
                        6,
                    ],
                    'line-opacity': [
                        'interpolate',
                        ['linear'],
                        ['get', 'VMAX'],
                        TRAJECTORIES_GRADIENT_COLORS[0].value,
                        0.01,
                        TRAJECTORIES_GRADIENT_COLORS[1].value,
                        0.05,
                        TRAJECTORIES_GRADIENT_COLORS[2].value,
                        0.1,
                    ],
                    'line-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'VMAX'],
                        TRAJECTORIES_GRADIENT_COLORS[0].value,
                        TRAJECTORIES_GRADIENT_COLORS[0].color,
                        TRAJECTORIES_GRADIENT_COLORS[1].value,
                        TRAJECTORIES_GRADIENT_COLORS[1].color,
                        TRAJECTORIES_GRADIENT_COLORS[2].value,
                        TRAJECTORIES_GRADIENT_COLORS[2].color,
                    ]
                },
            }); // stochastic trajectories layer

            //// *** Pulsing dot
            map.current.addImage('pulsing-dot-current', pulsatingDot(200, 'red'), { pixelRatio: 2 }); // pulsing dot source
            map.current.addImage('pulsing-dot', selectedPulsatingDot, { pixelRatio: 2 }); // pulsing dot source

            //// *** Static dot source
            map.current.addSource('static-dot-source', {
                'type': 'geojson',
                'data': static_dot_geojson,
            });

            //// *** Static dot layer
            map.current.addLayer({
                'id': 'static-dot-layer',
                'type': 'circle',
                'source': 'static-dot-source' ,
                'visibility': 'none',
                'paint': {
                    'circle-radius': 8,
                    'circle-color': 'black',
                    'circle-stroke-color': 'white',
                    'circle-stroke-width': 2,
                }
            })

            map.current.addLayer({
                'id': 'pulsing-dot-layer',
                'type': 'symbol',
                'source': 'pulsing-dot-source',
                'layout': {
                    'icon-image': 'pulsing-dot'
                },
            });

            if (map.current.getSource('latest-source')) {
                map.current.addLayer({
                    'id': 'layer-with-pulsing-dot',
                    'type': 'symbol',
                    'source': 'latest-source',
                    'layout': {
                        'icon-image': 'pulsing-dot-current'
                    },
                    'filter': ['all', ['==', '$type', 'Point'], ['>', 'DTG', PREV_DTG], ['<', 'DTG', NEXT_DTG]],
                }); // pulsing dot layer
            }
        })

    }, [] );

    //// on stormPositions, draw pulsating dot at current (interpolated) position
/*    useEffect(() => {
        if (selectedDTG && stormPositions.length > 0) {
            let prev = stormPositions.filter(x => x.DTG < TODAY).reverse()[0]
            let next = stormPositions.filter(x => x.DTG > TODAY)[0]
            let today_date = new Date()
            let prev_date = new Date(prev.DTG)
            let next_date = new Date(next.DTG)
            let f = (today_date - prev_date) / (next_date - prev_date)
            let x = prev.coordinates[0] + f * (next.coordinates[0] - prev.coordinates[0])
            let y = prev.coordinates[1] + f * (next.coordinates[1] - prev.coordinates[1])
            let coordinates = [x, y]
            console.log('hi')
        }
    }, [stormPositions])*/

    //// on selectedDTG change, push the coordinates of the pulsing dot
    useEffect(() => {
        if (selectedDTG && selectedStormData.stormPositions.length > 0) {

            console.log('useEffect on selectedDTG')
            console.log('\t' + selectedStorm.storm_name)

            // get coordinates and speed at selection's time
            let selection = selectedStormData.stormPositions.filter(x => x.DTG === selectedDTG)[0]
            let coordinates = selection.coordinates
            let speed = selection.VMAX

            marker.geometry.coordinates = coordinates

            // remove previous pulsating dot
            // map.current.removeImage('pulsing-dot')
            // map.current.addImage('pulsing-dot', pulsatingDot(125, WIND_COLORS[windCategory(speed)]), { pixelRatio: 2 });

            // update coordinates of pulsating dot
            static_dot_geojson.features.push(marker)
            map.current.getSource('static-dot-source').setData(static_dot_geojson)
            map.current.setPaintProperty('static-dot-layer', 'circle-color', WIND_COLORS[windCategory(speed)])
            map.current.setLayoutProperty('static-dot-layer', 'visibility', 'visible')

            // move pulsing-dot-layer to top
            map.current.moveLayer('static-dot-layer')

            // zoom on pulsating dot
            map.current.flyTo({center: coordinates, speed: 0.8, essential: true})
        }

        return () => {
            console.log('\tCLEANUP')
            map.current.setLayoutProperty('static-dot-layer', 'visibility', 'none')
        }

    }, [selectedDTG])

    //// on stormsThatHit change
    useEffect(() => {
        map.current.once('load', () => {
            if (stormsThatHit.length > 1) {
                drawAllStormsThatHit(map.current, allStorms)
            }
        })
    }, [stormsThatHit, allStorms])

    useEffect(() => {

        if (adm2) {
            // When the user moves their mouse over the state-fill layer, we'll update the
            // feature state for the feature under the mouse.
            map.current.on('click', 'adm2-fills', (e) => {
                hideAllStormsThatHit(map.current, allStorms)
                if (e.features.length > 0) {
                    if (hoveredAdmId !== null) {
                        map.current.setFeatureState(
                            { source: 'adm2-source', id: hoveredAdmId },
                            { hover: false }
                        );
                    }
                    hoveredAdmId = e.features[0].id;
                    map.current.setFeatureState(
                        { source: 'adm2-source', id: hoveredAdmId },
                        { hover: true }
                    );

                    const ADM0_CODE = e.features[0].properties.ADM0_CODE
                    const ADM1_CODE = e.features[0].properties.ADM1_CODE
                    const ADM2_CODE = e.features[0].properties.ADM2_CODE

                    setAdmName({adm0_name: e.features[0].properties.ADM0_NAME, adm1_name: e.features[0].properties.ADM1_NAME, adm2_name: e.features[0].properties.ADM2_NAME})
                    setAdmPopulation(numberWithCommas(e.features[0].properties.POPULATION))
                    setAdmValue(numberWithCommas(Math.round(e.features[0].properties.VALUE)))

                    const stormsThatHit_0 = stormsThatHit.find(adm0 => adm0.adm0_code === ADM0_CODE)
                    const stormsThatHit_1 = stormsThatHit_0.adm1.find(adm1 => adm1.adm1_code === ADM1_CODE)
                    const stormsThatHit_2 = stormsThatHit_1.adm2.find(adm2 => adm2.adm2_code === ADM2_CODE)

                    const counter_adm_keys = Object.keys(stormsThatHit_2.counter_adm2)

                    const ADM_STATS = counter_adm_keys.map((e) => {
                        return {
                            year: e,
                            counter_adm: stormsThatHit_2.counter_adm2[e],
                            counter_year: stormsThatHit_2.counter_year[e],
                            loss: stormsThatHit_2.loss[e]
                        }
                    })
                    setAdmStats(ADM_STATS)

                    setAdmAvgStorm(average(Object.values(stormsThatHit_2.counter_adm2)).toFixed(2))
                    setAdmBiggestLoss(numberWithCommas(Math.max(...Object.values(stormsThatHit_2.loss))))

                    showHideStormsThatHit(map.current, stormsThatHit_2.storms, true)
                }
            });

            // When the mouse leaves the state-fill layer, update the feature state of the
            // previously hovered feature.
            /*map.current.on('mouseleave', 'adm2-fills', () => {
                hideAllStormsThatHit(map.current, stormsThatHit)
                //removeStormsThatHit(map.current, stormsThatHitCurrent)
                if (hoveredAdmId !== null) {
                    map.current.setFeatureState(
                        { source: 'adm2-source', id: hoveredAdmId },
                        { hover: false }
                    );
                }
                hoveredAdmId = null;
                setAdmName(null)
                setAdmPopulation('')
                setAdmValue('')
                setAdmStats(null)
                setAdmAvgStorm('')
                setAdmBiggestLoss('')
            });*/
        }

        if (adm1) {
            // When the user moves their mouse over the state-fill layer, we'll update the
            // feature state for the feature under the mouse.
            map.current.on('click', 'adm1-fills', (e) => {
                hideAllStormsThatHit(map.current, allStorms)
                if (e.features.length > 0) {
                    if (hoveredAdmId !== null) {
                        map.current.setFeatureState(
                            { source: 'adm1-source', id: hoveredAdmId },
                            { hover: false }
                        );
                    }
                    hoveredAdmId = e.features[0].id;
                    map.current.setFeatureState(
                        { source: 'adm1-source', id: hoveredAdmId },
                        { hover: true }
                    );

                    const ADM0_CODE = e.features[0].properties.ADM0_CODE
                    const ADM1_CODE = e.features[0].properties.ADM1_CODE

                    setAdmName({adm0_name: e.features[0].properties.ADM0_NAME, adm1_name: e.features[0].properties.ADM1_NAME})
                    setAdmPopulation(numberWithCommas(e.features[0].properties.POPULATION))
                    setAdmValue(numberWithCommas(Math.round(e.features[0].properties.VALUE)))

                    const stormsThatHit_0 = stormsThatHit.find(adm0 => adm0.adm0_code === ADM0_CODE)
                    const stormsThatHit_1 = stormsThatHit_0.adm1.find(adm1 => adm1.adm1_code === ADM1_CODE)

                    const counter_adm_keys = Object.keys(stormsThatHit_1.counter_adm1)

                    const ADM_STATS = counter_adm_keys.map((e) => {
                        return {
                            year: e,
                            counter_adm: stormsThatHit_1.counter_adm1[e],
                            counter_year: stormsThatHit_1.counter_year[e],
                            loss: stormsThatHit_1.loss[e]
                        }
                    })
                    setAdmStats(ADM_STATS)

                    setAdmAvgStorm(average(Object.values(stormsThatHit_1.counter_adm1)).toFixed(2))
                    setAdmBiggestLoss(numberWithCommas(Math.max(...Object.values(stormsThatHit_1.loss))))

                    showHideStormsThatHit(map.current, stormsThatHit_1.storms, true)

                }
            });

            // When the mouse leaves the state-fill layer, update the feature state of the
            // previously hovered feature.
/*            map.current.on('mouseleave', 'adm1-fills', () => {
                hideAllStormsThatHit(map.current, stormsThatHit)
                if (hoveredAdmId !== null) {
                    map.current.setFeatureState(
                        { source: 'adm1-source', id: hoveredAdmId },
                        { hover: false }
                    );
                }
                hoveredAdmId = null;
                setAdmName(null)
                setAdmPopulation('')
                setAdmValue('')
                setAdmStats(null)
                setAdmAvgStorm('')
                setAdmBiggestLoss('')
            });*/
        }

        if (adm0) {
            // When the user moves their mouse over the state-fill layer, we'll update the
            // feature state for the feature under the mouse.
            map.current.on('click', 'adm0-fills', (e) => {
                hideAllStormsThatHit(map.current, allStorms)
                if (e.features.length > 0) {
                    if (hoveredAdmId !== null) {
                        map.current.setFeatureState(
                            { source: 'adm0-source', id: hoveredAdmId },
                            { hover: false }
                        );
                    }
                    hoveredAdmId = e.features[0].id;
                    map.current.setFeatureState(
                        { source: 'adm0-source', id: hoveredAdmId },
                        { hover: true }
                    );

                    const ADM0_CODE = e.features[0].properties.ADM0_CODE

                    setAdmName({adm0_name: e.features[0].properties.ADM0_NAME})
                    setAdmPopulation(numberWithCommas(e.features[0].properties.POPULATION))
                    setAdmValue(numberWithCommas(Math.round(e.features[0].properties.VALUE)))

                    const stormsThatHit_0 = stormsThatHit.find(adm0 => adm0.adm0_code === ADM0_CODE)

                    const counter_adm_keys = Object.keys(stormsThatHit_0.counter_adm0)

                    const ADM_STATS = counter_adm_keys.map((e) => {
                        return {
                            year: e,
                            counter_adm: stormsThatHit_0.counter_adm0[e],
                            counter_year: stormsThatHit_0.counter_year[e],
                            loss: stormsThatHit_0.loss[e]
                        }
                    })
                    setAdmStats(ADM_STATS)

                    setAdmAvgStorm(average(Object.values(stormsThatHit_0.counter_adm0)).toFixed(2))
                    setAdmBiggestLoss(numberWithCommas(Math.max(...Object.values(stormsThatHit_0.loss))))

                    showHideStormsThatHit(map.current, stormsThatHit_0.storms, true)
                }
            });

            // When the mouse leaves the state-fill layer, update the feature state of the
            // previously hovered feature.
/*            map.current.on('mouseleave', 'adm0-fills', () => {
                hideAllStormsThatHit(map.current, stormsThatHit)
                if (hoveredAdmId !== null) {
                    map.current.setFeatureState(
                        { source: 'adm0-source', id: hoveredAdmId },
                        { hover: false }
                    );
                }
                hoveredAdmId = null;
                setAdmName(null)
                setAdmPopulation('')
                setAdmValue('')
                setAdmStats(null)
                setAdmAvgStorm('')
                setAdmBiggestLoss('')
            });*/
        }

    }, [adm0, adm1, adm2])

    useEffect(() => {
        hideAllStormsThatHit(map.current, allStorms)
        showHideStormsThatHit(map.current, stormsToShow, true, 0.35, 0.7) //TODO: move all these numbers to constants
    }, [stormsToShow])

    //// update data and selected position of the selected storm
    useEffect(() => {
        if (selectedStorm) {
            updateSelectedDTG(null) // reinitialize the selected DTG (in case of storm change for instance)
            fly(map.current, selectedStorm) // custom function to zoom to the bounding box of the selected storm

            // if the source exists, show the selected storm and layers, if not, add the source
            if (map.current.getSource('latest-source') || (map.current.getSource('latest-source-nc'))) { //TODO:
                updateSourceStorm(map.current, selectedStorm)
                showHideStorm(map.current, selectedStorm, layers, true)
            } else {
                addSourceStorm(map.current, selectedStorm)
                drawStorm(map.current, selectedStorm, currentStorm)
            }
            ///
            getStormData(selectedStorm) // TODO: write a function that takes selectedStorm and spits out all the functions below
            ///
            getLatestStormID(selectedStorm.shp)
            getCurrentData(selectedStorm.shp)
            getStormPositions(selectedStorm.shp)
            getCurrentLosses(selectedStorm.losses)
            getVmax(selectedStorm.shp)
            getMaxSurge(selectedStorm.nc)
            map.current.on('click', 'storm-icons-layer', handleClick) //TODO: rename the 'storm-icon-layer'
        }

        // update the selected DTG once a storm's circle is clicked
        function handleClick(e) {
            if (e.features.length > 0) {
                console.log('\t' + 'clicked on circle and updates selectedDTG ' + selectedStorm.storm_name)
                updateSelectedDTG(e.features[0].properties.DTG)
            }
        }

        // clean-up
        return () => {
            map.current.off('click', 'storm-icons-layer', handleClick)
        }
    }, [selectedStorm])

    /// update the visibility of dynamic layers (independent of a selected storm)
    useEffect(() => {
        for (const [layerId, isVisible] of Object.entries(layers)) {
            if (map.current.getLayer(layerId)) {
                map.current.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
            }
        }
    }, [accRain3h, accRain1d, fcstRain1d, fcstRain5d, accRain1dVideo, fcstRain1dVideo])

    /// update the visibility of dynamic layers (dependent of a selected storm)
    useEffect(() => {
        if (selectedStorm) {
            for (const [layerId, isVisible] of Object.entries(layers)) {
                if (map.current.getLayer(layerId)) {
                    map.current.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
                }
            }
        }
    }, [pastPeakWind, pastPeakWater, pastRainTotal])

    /// update the visibility of static layers (independent of a selected storm)
    useEffect(() => {
        for (const [layerId, isVisible] of Object.entries(layersStatic)) {
            if (map.current.getLayer(layerId)) {
                map.current.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
            }
        }
    }, [population, value, stochastic_heatmap, stochastic_trajectories]);

    return (
            <div ref={mapContainer} className="map-container" id={'map-container'}>

                <div className={'logo-title'}>
                    <TceTitle />
                </div>

                <div className={'copyright'}>
                    <p>&copy; African Risk Capacity</p>
                </div>

                <div className={'arc-logo'}>
                    <ArcLogo style={{width: '150px', height: '150px'}}/>
                </div>

                <div className={'storm-menu'}>
                    <SelectedStorm allStorms={allStorms}/>
                </div>

                <div className={'exploration-menu'}>
                    <SelectedExploration />
                </div>

                <div>
                    <Settings />
                </div>

                <div>
                    <Languages />
                </div>


                {!currentStorm
                &&
                <div className={'alert'}>
                    <Collapse in={openAlert}>
                        <Alert
                            severity="warning"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {t('alert.no_active_storm')}
                        </Alert>
                    </Collapse>
                </div>
                }

                <div className={'info'}>
                    {(stormName) &&
                    <div className={'storm-info'}>
                        <Divider />
                        {/*{storm && <div><span>Year: <strong className="storm-year">{stormYear}</strong></span></div>}*/}
                        {storm && <div><span>ID: <strong className="storm-id">{stormId}</strong></span></div>}
                    </div>
                    }
                    {
                        storm &&
                            <div className={'layer-info'}>
                                <div>Layers</div>
                                <Divider />
                                {
                                    Object.entries(layers).map(([key, value]) =>
                                        (
                                            <div>
                                                {value && <CheckCircleIcon className={'check-circle-icon'}/>}
                                                {value && key}
                                            </div>
                                        )
                                    )
                                }
                            </div>
                    }
                </div>

                {selectedStorm && currentLosses.length > 0 &&
                <div>
                    <PopUpReport
                        storm_name={selectedStorm.storm_name}
                        id={selectedStorm.id}
                        map={map.current}
                        currentLosses={currentLosses}
                    />
                </div>
                }

                <div>
                    {selectedStorm && currentLosses && todayData && vmax && selectedStormData &&
                        <StormOverlay
                            selectedStorm = {selectedStorm}
                            selectedStormData = {selectedStormData}
                            currentLosses = {currentLosses}
                            stormPositions = {selectedStormData.stormPositions}
                            todayData = {todayData}
                            vmax = {selectedStormData.vmax}
                            maxSurge = {maxSurge}
                            currentStorm = {currentStorm}
                            open = {true}
                            clearMapSelectedStorm={clearMapSelectedStorm}
                        />
                    }
                </div>

                <div>
                    {(adm0 || adm1 || adm2) &&
                        <AdmOverlay
                            admName={admName}
                            admPopulation={admPopulation}
                            admValue={admValue}
                            admAvgStorm={admAvgStorm}
                            admBiggestLoss={admBiggestLoss}
                            data={admStats}
                            open={true}
                            clearMap={clearMap}
                        />
                    }
                </div>

                { accRain1dVideo &&
                    <div>
                        <TimeAnimation
                            days={7}
                            map={map}
                            openTimeline={true}
                            getPathFn={getPathPPMs}
                            datasetName={'accRain1dVideo'}
                        />
                    </div>
                }

                { fcstRain1dVideo &&
                    <div>
                        <TimeAnimation
                            days={-7}
                            map={map}
                            openTimeline={true}
                            getPathFn={getPathGFSs}
                            datasetName={'fcstRain1dVideo'}
                        />
                    </div>
                }

            </div>
    );
}
