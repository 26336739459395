import React, {useState, useContext, useEffect} from "react";
import { useTranslation, Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {DataContext} from '../contexts/DataContext';
import {Divider} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tooltip from '@mui/material/Tooltip';
import AdmSelection from "./admSelection";
import SliderYear from "./sliderYear";
import StormsTreeView from "./stormsTreeView";
import CloseButton from "./closeButton";

export default function SelectedStorm(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {allStorms} = props;

    const { t } = useTranslation();

    const [currentStorms, setCurrentStorms] = useState([])
    const [latestStorms, setLatestStorms] = useState([])
    const [pastStorms, setPastStorms] = useState([])
    const [pastStormsSeason, setPastStormsSeason] = useState([])
    const {
        storm,
        currentStorm,
        pastStorm,
        updatePastStorm,
        current_storm,
        updateStorm,
        selectedStorm,
        updateSelectedStorm,
        updateCurrentStorm,
        updateCurrent_Storm,
        latest_storms,
        updateLatestStorms,
        url_root,
        updateStormsToShow
    } = useContext(DataContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        //updateStormsToShow([]);
    };

    const handleChange = (event) => {
        updateStorm(event.target.value);
    };

    const handleSelectedStormSeason = (event) => {
        setAnchorEl(false)
        if (latestStorms.map(x => x.id).includes(event.target.value)) {
            updateSelectedStorm(latestStorms.filter((i) => i.id === event.target.value)[0]);
        } else if (currentStorms.map(x => x.id).includes(event.target.value)) {
            updateSelectedStorm(currentStorms.filter((i) => i.id === event.target.value)[0]);
        } else {
            updateSelectedStorm(pastStorms.filter((i) => i.id === event.target.value)[0]);
        }
    }

    useEffect(() => {
        fetch(url_root + 'pastStorms.json') //note here the local path is relative to "index.html" instead of "index.js"
            .then(resp => resp.json())
            .then(resp => resp.jtwc_history)
            .then(resp => resp.map(x => ({
                storm_name: x.storm_name,
                id: x.id,
                year: x.year,
                nc: x.nc ? url_root + x.nc : null,
                shp: x.shp ? url_root + x.shp : null,
                losses: x.losses ? url_root + x.losses : null,
                type: 'past',
                bbox: x.bbox
            })))
            .then(resp => {
                //updateCurrentStorm(false)
                setPastStorms(resp)
            })
    }, [])

    useEffect(() => {
        fetch(url_root + 'pastStormsSeason.json') //note here the local path is relative to "index.html" instead of "index.js"
            .then(resp => resp.json())
            .then(resp => resp.jtwc_history)
/*            .then(resp => resp.map(x => ({
                storm_name: x.storm_name,
                id: x.id,
                year: x.year,
                nc: x.nc ? url_root + x.nc : null,
                shp: x.shp ? url_root + x.shp : null,
                losses: x.losses ? url_root + x.losses : null,
                type: 'past',
                bbox: x.bbox,
                date: x.date ? x.date : '',
                time: x.time ? x.time : ''
            })))*/
            .then(resp => {
                //updateCurrentStorm(false)
                setPastStormsSeason(resp)
            })
    }, [])

    useEffect(() => {
        fetch(url_root + 'latestStorms.json') //note here the local path is relative to "index.html" instead of "index.js"
            .then(resp => resp.json())
            .then(resp => resp.mpres_data)
            .then(resp => resp.map(x => ({
                storm_name: x.storm_name,
                id: x.id,
                shp: url_root + x.shp,
                nc: x.nc ? url_root + x.nc : null,
                losses: x.losses ? url_root + x.losses : '',
                type: 'season',
                bbox: x.bbox
            })))
            .then(resp => {
                //updateCurrentStorm(false)
                setLatestStorms(resp)
                //updateLatestStorms(latestStorms)
            })
    }, [])

    useEffect(() => {
        const shp = 'mpres_data/taos_swio_ofcl_storms_shp.geojson'
        fetch(url_root + 'latestStorms.json') //note here the local path is relative to "index.html" instead of "index.js"
            .then(resp => resp.json())
            .then(resp => resp.tc_realtime)
            .then(resp => resp.map(x => ({
                storm_name: x.storm_name,
                id: x.id,
                shp: url_root + shp,
                nc: url_root + x.jtwc,
                fmee: url_root + x.fmee,
                jtwc: url_root + x.jtwc,
                losses: url_root + x.losses,
                type: 'current',
                bbox: x.bbox,
            })))
            .then(resp => {
                setCurrentStorms(resp)
                if (resp.length > 0) {
                    updateCurrentStorm(true)
                }
            })
    }, [])


    return (
        <Box sx={{ minWidth: 120, boxShadow: 20, marginLeft:2}}>
            <Tooltip placement={'top'} title={t('buttons.storms.helper')}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {/*                <Trans i18nKey='buttons.storms'>
                    Storms
                </Trans>*/}
                    {t('buttons.storms.title')}
                </Button>
            </Tooltip>
{/*            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div className={'select-storm'}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Storm</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedStorm ? selectedStorm.storm_name : ''}
                            label="Current storms"
                            onChange={handleSelectedStormSeason}
                        >
                            {currentStorms.map((value) => {
                                return <MenuItem value={value.id} key={value.id}>{value.storm_name} (current)</MenuItem>
                            })}
                            <Divider />
                            {latestStorms.map((value) => {
                                return <MenuItem value={value.id} key={value.id}>{value.storm_name}</MenuItem>
                            })}
                            <Divider />
                            {pastStorms.map((value) => {
                                return <MenuItem value={value.id} key={value.id}>{value.storm_name !== 'NONAME' ? value.storm_name : ''} ({value.id})</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>
            </Menu>*/}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <CloseButton handleClose={handleClose}/>
                {/*TODO: check what each parameter contains exactly*/}
                <StormsTreeView pastStormsSeason={pastStormsSeason} pastStorms={pastStorms} latestStorms={latestStorms} allStorms={allStorms}/>
{/*            {
                open &&
                <StormsTreeView pastStormsSeason={pastStormsSeason} pastStorms={pastStorms} latestStorms={latestStorms} stormsThatHit={stormsThatHit}/>
            }*/}
            </Menu>



        </Box>
    );
}
